import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Provincia } from '../models/provincia';
import { ResourcefulService } from './resourcefulService';



@Injectable({
    providedIn: 'root'
})
export class ProvinciaService extends ResourcefulService<Provincia>{
    constructor(private httpClient: HttpClient) {
        super(httpClient, Provincia);
    }
}
