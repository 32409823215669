import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/user';
import { baseUrl } from './api-config';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl = baseUrl;

  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public loggedInUser: BehaviorSubject<User> = new BehaviorSubject(new User());

  constructor(private httpClient: HttpClient) {
    const loggedUser = this.getLoggedInUserFromLocalStorage();
    if (loggedUser) {
      this.loggedInUser.next(loggedUser);
    }

    const isLogged = this.isLoggedIn();
    if (isLogged) {
      this.loggedIn.next(isLogged);
    }
  }

  login(credentials: { email, password }) {

    return this.httpClient.post(`${this.baseUrl}/login`, credentials)
      .toPromise().then(data =>
        this.setSession(data)
      );

  }


  private setSession(authResult) {
    localStorage.setItem('access_token', authResult.access_token);

    let user = new User().deserialize(authResult.user);

    localStorage.setItem('loggedInUser', JSON.stringify(user));

    this.loggedIn.next(true);
    this.loggedInUser.next(user);
  }

  public getAccessToken() {
    return localStorage.getItem('access_token');
  }

  getLoggedInUserFromLocalStorage(): User {
    return new User().deserialize(JSON.parse(localStorage.getItem('loggedInUser')));
  }

  public logout() {
    localStorage.removeItem("access_token");
    this.loggedIn.next(false);

    this.loggedInUser.next(null);
  }

  public isLoggedIn() {
    return !!localStorage.getItem('access_token');
  }

  public isLoggedOut() {
    return !this.isLoggedIn();
  }

}
