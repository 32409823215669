import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Model } from 'src/app/models/model';
import { dataTableColumnDefinition } from '../dataTableColumnDefinition';
import { ResourcefulIndexComponent } from '../resourceful-index/resourceful-index.component';

@Component({
  selector: 'resourceful-worksheet',
  templateUrl: './resourceful-worksheet.component.html',
  styleUrls: ['./resourceful-worksheet.component.scss']
})
export class ResourcefulWorksheetComponent<C extends Model> extends ResourcefulIndexComponent {

  @Input() columnDefinitions: dataTableColumnDefinition[] = [
    new dataTableColumnDefinition(
      {
        humanName: 'ID',
        internalName: 'id',

      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Nome',
        internalName: 'nome',

      }
    ),
  ];

  @Output() edited: EventEmitter<C> = new EventEmitter<C>();
  @Output() duplicated = new EventEmitter<{ model: C, times: number }>();

  inputBlurred(model) {
    this.edited.next(model);
  }


  @Input() allowDuplication = true;

  duplicate(model: C) {
    let answer = prompt("Quante volte vuoi duplicare la riga?");
    let times = +answer;
    this.duplicated.next({ model, times });
  }
}
