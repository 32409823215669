import { ModelWithAddressAndContactInfo } from './model-with-address-contact-info';
import { Ruolo } from "./ruolo";
export class User extends ModelWithAddressAndContactInfo {
  static apiUrl: string = "users";
  titolo: string;
  qualifica: string;
  nome: string;
  cognome: string;
  ruoli: Ruolo[];



  public hasRole(roleName: string) {
    try {
      const searchedRoleName = roleName.toLowerCase().trim();
      return this.ruoli.some(x => x.nome.toLowerCase().trim() == searchedRoleName);
    }
    catch (e) {
      return false;
    }
  }
  public hasAnyRole(roleNames: string[]) {
    try {
      return roleNames.some((searchedRole) => this.hasRole(searchedRole));
    }
    catch (e) {
      return false;
    }
  }

  deserialize(input: any): User {
    let retV: User = new User();

    retV = super.deserialize(input) as User;
    if (input) {

      if (input?.ruoli) {
        this.ruoli = input.ruoli.map(r => new Ruolo().deserialize(r));
      }

    }
    return retV;
  }


  public getHumanIdentifier() {
    if (this.titolo)
      return `${this.titolo} ${this.nome} ${this.cognome}`;
    return `${this.nome} ${this.cognome}`;
  }

}
