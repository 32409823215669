import { Model } from './model';
import { Provincia } from './provincia';

export class Comune extends Model {
  static apiUrl: string = "comuni";

  nome: string;

  provincia: Provincia;

  provincia_id: number;

  cap: number;

  deserialize(input: any): Comune {
    let retV: Comune = new Comune();
    retV = super.deserialize(input) as Comune;

    if (input?.provincia) {
      retV.provincia = new Provincia().deserialize(input.provincia);
    }

    return retV;
  }

}
