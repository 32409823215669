<div class="container-fluid pb-3 pt-2">
  <h4 class="mb-2">{{headingText}}</h4>
  <div *ngIf="!loading; else loader">

    <div *ngIf="form" class="row" [formGroup]="form">
      <div class="col-6">
        <mat-form-field>
          <buffered-input placeholder="Filtra per nome" formControlName="nome"></buffered-input>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field>
          <regione-picker placeholder="Filtra per regione" formControlName="regione"></regione-picker>
        </mat-form-field>
      </div>
    </div>

    <resourceful-index #index [search]="search" [modelService]="provinceService" [deleteFunction]="deleteFunction"
      [columnDefinitions]="columnDefinitions" [editFunction]="editFunction" [selectionMode]="selectionMode"
      (selectedModel)="closeDialogWithSelectedModel($event)" [relations]="relations">
    </resourceful-index>

    <button mat-raised-button color="primary" class="mt-4" (click)="add()">
      Aggiungi una Provincia<mat-icon>add</mat-icon>
    </button>

  </div>
  <ng-template #loader>
    <mat-spinner></mat-spinner>
  </ng-template>
</div>