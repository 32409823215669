import { Component, forwardRef, Input } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Observable } from 'rxjs';
import { ExternalSelectorComponent } from '../../external-selector/external-selector.component';
import { ComuniComponent } from '../../../../admin/comuni/comuni.component';
import { FormGroup } from '@angular/forms';

@Component({
  providers: [
    { provide: MatFormFieldControl, useExisting: forwardRef(() => ComunePickerComponent) },
  ],
  selector: 'comune-picker',
  templateUrl: '../../external-selector/external-selector.component.html',
  styleUrls: ['../../external-selector/external-selector.component.scss']
})
export class ComunePickerComponent extends ExternalSelectorComponent {

  @Input()
  get externalEntityObserver(): Observable<any> {
    return this.dialog.open(ComuniComponent, { data: { selectionMode: true } }).afterClosed();
  };

  /** Aggiungiamo il formcontrol "nome".
   * Questa aggiunta serve perchè il form del fabbricato
   * deserializza ad ogni value changes il fabbricato;
   * se non c'è il nome nel comune ma solo humanIdentifier dopo si vede il control vuoto ma con la label fluttuante.
   */
  parts: FormGroup = this.fb.group({
    id: [''],
    humanIdentifier: ['',],
    nome: ['',],
  });


}
