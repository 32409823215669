<div class="container-fluid pb-3 pt-2">
  <h4 class="mb-2">{{headingText}}</h4>
  <div *ngIf="!loading; else loader">
    <mat-accordion>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Ricerca
          </mat-panel-title>
          <mat-panel-description>
            Ricerca avanzata utenti
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ricerca-utenti [(search)]="search"></ricerca-utenti>
      </mat-expansion-panel>
    </mat-accordion>

    <resourceful-index #index [search]="search" [modelService]="userService" [deleteFunction]="deleteFunction"
      [columnDefinitions]="columnDefinitions" [editFunction]="editFunction" [selectionMode]="selectionMode"
      (selectedModel)="closeDialogWithSelectedModel($event)" [relations]="relations">
    </resourceful-index>

    <button mat-raised-button color="primary" class="mt-4" (click)="add()">
      Aggiungi un utente<mat-icon>add</mat-icon>
    </button>

  </div>
  <ng-template #loader>
    <mat-spinner></mat-spinner>
  </ng-template>
</div>