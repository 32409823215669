<custom-sidebar heading="Collegamenti" [routes]="sidebarRoutes$ | async" contentMode="ng-content" position="start"
  [opened]="false">
  <div class="container-fluid pb-4">

    <mat-divider class="my-4"></mat-divider>

    <div class="row">
      <div class="card-wrapper">
        <mat-card>
          <mat-card-header>
            <div mat-card-avatar>
              <mat-icon>euro_symbol</mat-icon>
            </div>
            <mat-card-title>Attività & Rendicontazione</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <ul>
              <li>
                <a routerLink="/attivita">Attività</a>
              </li>
              <li>
                <a routerLink="/rendicontazione">Rendicontazione</a>
              </li>
            </ul>
          </mat-card-content>
        </mat-card>
      </div>

    </div>
    <mat-divider class="my-3"></mat-divider>


  </div>

</custom-sidebar>