import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignatureInputComponent } from './signature-input.component';



@NgModule({
  declarations: [SignatureInputComponent],
  imports: [
    CommonModule,
    SignaturePadModule,
  ],
  exports: [SignatureInputComponent,]
})
export class SignatureInputModule { }
