import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableExporterDirective } from 'mat-table-exporter';
import { ToastrService } from 'ngx-toastr';
import { emit } from 'process';
import { BehaviorSubject } from 'rxjs';
import { Model } from 'src/app/models/model';
import { PaginationInfo } from 'src/app/models/paginationInfo';
import { Rendicontazione } from 'src/app/models/rendicontazione';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { ResourcefulService } from 'src/app/services/resourcefulService';
import { dataTableColumnDefinition } from '../dataTableColumnDefinition';
import { PaginatorComponent } from '../paginator/paginator.component';

@Component({
  selector: 'resourceful-index',
  templateUrl: './resourceful-index.component.html',
  styleUrls: ['./resourceful-index.component.scss']
})
export class ResourcefulIndexComponent implements OnInit {
  selection = new SelectionModel<any>(true, []);
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection?.selected?.length;
    const numRows = this.models?.length || 0;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.models?.forEach(row => this.selection.select(row));
  }

  @Input() pagination = true;

  @Input() columnDefinitions: dataTableColumnDefinition[] = [
    new dataTableColumnDefinition(
      {
        humanName: 'ID',
        internalName: 'id',

      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Nome',
        internalName: 'nome',

      }
    ),
  ];

  @Input() selectionMode: boolean = false;

  @Input() multipleSelection: boolean = false;

  @Output() selectedModel: EventEmitter<Model> = new EventEmitter();
  form: FormGroup; //form that keeps track of which columns are hidden and which not

  computeNamesOfColumnsThatShouldBeDisplayed() {
    let columnNames = [];

    //Warning: non manipolare le colonne da visualizzare prima di queste righe che le filtrano in base alla checkbox nell'header della colonna
    //ad esempio se aggiungo una colonna qui tipo this.columnDefinitions.push("colonnaACaso") dopo verrà tolta dal filter.

    columnNames = columnNames.concat( //add all internal names of column definitions
      this.columnDefinitions.map(columnDefinition => columnDefinition.internalName)
    );

    if (this.allowHidingColumns && this.form) { //if it's allowed to hide columns (default: true) then filter out the columns that aren't checked in the column selector.
      columnNames = columnNames.filter(v => this.isChecked(v));
    }
    //Sezione di aggiunta colonne speciali (selezioni, azioni varie)
    if (this.multipleSelection) //if multiple selection required...
      columnNames.unshift('select');

    //if there's at least 1 action push actions col
    if (this.deleteFunction || this.editFunction)
      columnNames.push('actions');

    if (this.selectionMode) //if single selection required, put single selection col at beginning and end of table columns
    {
      columnNames.push('selectThisRow');
      columnNames.unshift('selectThisRow');

    }
    return columnNames;
  }

  namesOfColumnsThatShouldBeDisplayed?: string[];

  toggleVal(column) {
    this.form?.controls[column.internalName]?.setValue(!this.form?.controls[column.internalName]?.value);
  }

  isChecked(name: string): boolean {
    return !!this.form?.controls[name]?.value;
  }

  @Input() deleteFunction: (model) => any;
  @Input() editFunction: (model) => any;


  @Input() canDeleteModels: (user: User) => boolean = () => true;

  @Input() relations: string[] = [];
  @Input() modelService: ResourcefulService<any>;

  @Input() set search(newSearch) {
    this._search = this.removeNullsFromSearch(newSearch);

    this.customPaginator?.searchChanged();
  };
  _search: any;

  //custom paginator
  @ViewChild('customPaginator') public customPaginator: PaginatorComponent;

  models: Model[];
  _models: Model[];

  loading: boolean = true;

  loggedInUser: User;

  currentSort = new BehaviorSubject<MatSort>({} as MatSort);
  @ViewChild(MatSort) sort: MatSort;

  constructor(private toast: ToastrService,
    public dialog: MatDialog,
    private authService: AuthService
  ) { }

  async ngOnInit() {
    this.form = new FormGroup({});
    for (let col of this.columnDefinitions) {
      this.form.addControl(col.internalName, new FormControl([true]));
    }

    this.namesOfColumnsThatShouldBeDisplayed = this.computeNamesOfColumnsThatShouldBeDisplayed();

    this.form.valueChanges.subscribe(v => {
      this.namesOfColumnsThatShouldBeDisplayed = this.computeNamesOfColumnsThatShouldBeDisplayed();
    })

    this.loggedInUser = await this.authService.loggedInUser.getValue();

    await this.updateData();
  }

  @Output() newModelsLoaded: EventEmitter<Model[]> = new EventEmitter();

  @Output() sumNetto: EventEmitter<any> = new EventEmitter();
  @Output() sumLordo: EventEmitter<any> = new EventEmitter();
  @Output() sumPrev: EventEmitter<any> = new EventEmitter();
  @Output() sumCons: EventEmitter<any> = new EventEmitter();

  public async updateData() {
    let paginationInfo: PaginationInfo = {
      sortDirection: this.currentSort?.getValue().direction,
      sortedColumn: this.currentSort?.getValue().active,
      //make page index start by 0 for easier usage in backend (subtract 1)
      requestedPage: this.customPaginator?.currentPage - 1,
      objectsPerPage: this.customPaginator?.objectsPerPage,
      search: this._search,
      desiresPagination: true,
    }
    try {
      this.loading = true;
      this.models = await this.modelService.getAll(this.relations, paginationInfo);

      this.customPaginator?.updatePaginationNumbers(this.modelService.count);
      this.newModelsLoaded.emit(this.models);
    }
    catch (e) {
      console.error(e);
      this.toast.error("Impossibile caricare i modelli", "Errore!")
    }
    this.loading = false;
  }

  public sommaNetto(value: any) {
    this.sumNetto.emit(value);
  }
  public sommaLordo(value: any) {
    this.sumLordo.emit(value);
  }
  public sommaPreventivo(value: any) {
    this.sumPrev.emit(value);
  }
  public sommaConsuntivo(value: any) {
    this.sumCons.emit(value);
  }

  async applySort(sort: MatSort) {
    this.currentSort.next(sort);
    await this.updateData();
  }

  public selectedRow(model) {
    this.selectedModel.emit(model);
  }


  removeNullsFromSearch(originalSearch: any): any {

    let newSearch = {};

    for (const key in originalSearch) {
      if (originalSearch[key] !== null) {
        newSearch[key] = originalSearch[key];
      }
    }
    return newSearch;
  }

  @Input() allowHidingColumns = true;

  @ViewChild(MatTableExporterDirective) exporter: MatTableExporterDirective;

  public cacheModels(models) {
    if (this._models && this._models.length) return this._models
    this._models = models
    return this._models
  }

}
