import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { HomeModule } from './home/home.module';
import { getItalianPaginatorIntl } from './italian-paginator';
import { CustomMaterialModule } from './material-module/material.module';
import { NavbarModule } from './navbar/navbar.module';
import { ErrorHandlingService } from './services/error-handling.service';
import { JwtInterceptor } from './services/jwt-interceptor';

/* import locale info for currency pipe */
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
registerLocaleData(localeIt);



@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CustomMaterialModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    AuthModule,
    NavbarModule,
    AdminModule,
    MatMomentDateModule, //to use moment.
    HomeModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: MatPaginatorIntl, useValue: getItalianPaginatorIntl()
    },
    {
      provide: LOCALE_ID, useValue: 'it'
    },
    {
      provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'
    },
    {
      provide: ErrorHandler, useClass: ErrorHandlingService
    },
    {
      //globally cause input errors to show when the input is dirty and invalid
      provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher
    }

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
