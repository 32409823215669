<h4 mat-dialog-title>
  <span *ngIf="!data;else editHeader">
    Creazione Nuova Provincia
  </span>
  <ng-template #editHeader>
    Modifica Provincia {{data.nome}}
  </ng-template>
</h4>
<form *ngIf="form" [formGroup]="form" class="mt-4" (keydown.enter)="edit()">

  <div>
    <mat-form-field>
      <mat-label>Nome</mat-label>
      <input matInput formControlName="nome">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>Codice</mat-label>
      <input maxlength="2" minlength="2" matInput formControlName="codice">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <regione-picker placeholder="Regione" formControlName="regione"></regione-picker>
    </mat-form-field>
  </div>
</form>
<button mat-raised-button (click)="edit()" color="primary">Salva</button>