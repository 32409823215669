<h4 mat-dialog-title>
  <span *ngIf="!data;else editHeader">
    Creazione Nuovo Comune
  </span>
  <ng-template #editHeader>
    Modifica Comune {{data.nome}}
  </ng-template>
</h4>
<form [formGroup]="form" class="mt-4" (keydown.enter)="edit()">

  <div>
    <mat-form-field>
      <mat-label>Nome</mat-label>
      <input matInput formControlName="nome">
      <mat-icon matSuffix>nome</mat-icon>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-label>CAP</mat-label>
      <input matInput formControlName="cap">
      <mat-icon matSuffix>cap</mat-icon>
    </mat-form-field>
  </div>

  <div>

    <mat-form-field>
      <provincia-picker placeholder="Provincia" formControlName="provincia"></provincia-picker>
    </mat-form-field>


  </div>
</form>
<button mat-raised-button (click)="edit()" color="primary">Salva</button>
