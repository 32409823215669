import { AfterViewInit, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/material-module/confirm-dialog/confirm-dialog.component';
import { Provincia } from 'src/app/models/provincia';
import { ProvinciaService } from 'src/app/services/provincia.service';
import { dataTableColumnDefinition } from 'src/app/shared/resourceful-index/dataTableColumnDefinition';
import { ResourcefulIndexComponent } from 'src/app/shared/resourceful-index/resourceful-index/resourceful-index.component';
import { EditComponent } from './dialogs/edit/edit.component';

@Component({
  selector: 'province',
  templateUrl: './province.component.html',
  styleUrls: ['./province.component.less']
})
export class ProvinceComponent implements OnInit, AfterViewInit {

  headingText: string = 'Province';

  relations: string[] = ['regione'];

  @ViewChild('index') index: ResourcefulIndexComponent;

  selectionMode: boolean = false;

  loading: boolean = true;

  search: any;

  columnDefinitions: dataTableColumnDefinition[] = [
    new dataTableColumnDefinition(
      {
        humanName: 'ID',
        internalName: 'id',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Nome',
        internalName: 'nome',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Codice',
        internalName: 'codice',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Regione',
        internalName: 'regione',
        sortDisabled: true,
        displayFunction: (model) => model.regione?.humanIdentifier,

      }
    ),
  ];
  constructor(public provinceService: ProvinciaService,
    public dialog: MatDialog,
    private toast: ToastrService,
    @Optional() private dialogRef: MatDialogRef<ProvinceComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,) {
    if (this.data?.selectionMode) {
      this.selectionMode = true;
      this.headingText = 'Seleziona una provincia';
    }
  }

  form: FormGroup;

  async ngOnInit() {
    this.form = this.fb.group({
      nome: [],
      regione: [],
    });

    this.form.valueChanges.subscribe(newValues => {
      this.search = newValues;
    });

  }

  async ngAfterViewInit(): Promise<void> {
    await this.updateDataSource();
    this.loading = false;
  }

  closeDialogWithSelectedModel(model) {
    this.dialogRef?.close(model);
  }

  async updateDataSource() {
    await this.index?.updateData();
  }


  deleteFunction = async (province: Provincia) => {
    try {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: `Sei sicuro di voler eliminare la provincia ${province.nome}?` } });
      dialogRef.afterClosed().subscribe(async data => {
        if (data) {
          await this.provinceService.delete(province);
          this.toast.success('Provincia eliminata.');
          this.updateDataSource();
        }
      });
    } catch (e) {
      console.error(e);
      this.toast.error("Eliminazione provincia fallita.", "Errore!");
    }
  }



  editFunction = async (province: Provincia) => {
    try {
      let dialogRef = this.dialog.open(EditComponent, { data: province });
      dialogRef.afterClosed().subscribe(async (data: Provincia) => {
        if (data) {

          Object.assign(province, data);

          await this.provinceService.edit(province);

          this.toast.success('Provincia modificata');
          this.updateDataSource();

        }
      });

    } catch (e) {
      console.error(e);
      this.toast.error("Modifica provincia fallita.", "Errore!");
    }
  }


  add() {
    let addDialogRef = this.dialog.open(EditComponent, { data: this.search });
    addDialogRef.afterClosed().subscribe(data => this.handleCreation(data));
  }

  async handleCreation(province?: Provincia) {
    if (province) {
      try {
        await this.provinceService.create(province)
        this.toast.success('Provincia creata!')
      }
      catch (e) {
        this.toast.error('Creazione provincia fallita.', 'Errore!');
      }
      finally {
        this.updateDataSource();
      }

    }
  }

}
