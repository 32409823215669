<mat-toolbar class="mat-elevation-z4" color="primary" *ngIf="user">
  <mat-toolbar-row>
    <a href="/home" routerLink="/home" style="color:white;text-decoration: none;">
      Eventi DueCi
    </a>

    <div class="ml-auto">
      <button *ngIf="user.hasAnyRole(['amministratore']) " mat-button href="/admin/users"
        [routerLink]="['/admin/users']">Pannello
        Admin</button>

      <button mat-button [matMenuTriggerFor]="menu">
        {{user.humanIdentifier}}
        <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button href="/user" routerLink="user" mat-menu-item>
          <span>Pagina Utente</span>
          <span>
            <mat-icon>person</mat-icon>
          </span>
        </button>

        <button (click)="emitLogoutEvent()" mat-menu-item>
          <span>Logout</span>
          <span>
            <mat-icon>exit_to_app</mat-icon>
          </span>
        </button>
      </mat-menu>

    </div>

  </mat-toolbar-row>
</mat-toolbar>