<div class="row mt-2 custom-width">
  <div class="col-3">
    <mat-form-field>
      <mat-label>Oggetti per pagina:</mat-label>
      <mat-select [(value)]="objectsPerPage" (selectionChange)="searchChanged()">
        <mat-option [value]="5">
          5
        </mat-option>
        <mat-option [value]="10">
          10
        </mat-option>
        <mat-option [value]="20">
          20
        </mat-option>
        <mat-option [value]="40">
          40
        </mat-option>
        <mat-option [value]="80">
          80
        </mat-option>
        <mat-option [value]="160">
          160
        </mat-option>
        <mat-option [value]="320">
          320
        </mat-option>
        <mat-option value="640">
          640
        </mat-option>
        <mat-option value="1280">
          1280
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-6 my-auto">
    Pagina Corrente: {{currentPage}} di {{lastPage || "1"}} (Tot.:{{objectsCount | number}})
    <span class="ml-3 paginator-buttons">
      <span matTooltip="Prima pagina" [class.disabled]="currentPage==1" (click)="changePage(1)">
        <mat-icon>first_page</mat-icon>
      </span>
      <span matTooltip="Pagina precedente" [class.disabled]="currentPage<=1" (click)="changePage(currentPage-1)">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </span>
      <span matTooltip="Prossima pagina" [class.disabled]="(currentPage==lastPage) || !lastPage"
        (click)="changePage(currentPage+1)">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </span>
      <span matTooltip="Ultima pagina" [class.disabled]="currentPage==lastPage || !lastPage"
        (click)="changePage(lastPage)">
        <mat-icon>last_page</mat-icon>
      </span>
    </span>
  </div>
  <div class="col-3 text-right  my-auto">
    <button mat-raised-button color="primary" (click)="tableExporter.exportTable('xlsx', getExportOptions())">
      Esporta in Excel <mat-icon>table_view</mat-icon>
    </button>
  </div>
</div>