import { Model } from './model';
import { User } from './user';

export class Ruolo extends Model {
  static apiUrl: string = "ruoli";

  nome: string;
  users?: User[]

  deserialize(input: any): Ruolo {
    let retV: Ruolo = new Ruolo();
    retV = super.deserialize(input) as Ruolo;

    if (input) {
      if (input?.users)
        this.users = input.users.map(u => new User().deserialize(u));
    }

    return retV;
  }


}
