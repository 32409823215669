<div class="container-fluid pb-3 pt-2">

  <h4>
    {{headingText}}
  </h4>

  <div *ngIf="!loading; else loader">

    <form *ngIf="form" class="row" [formGroup]="form">
      <div class="col-6">
        <mat-form-field>
          <buffered-input placeholder="Filtra per nome" formControlName="nome"></buffered-input>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field>
          <provincia-picker placeholder="Filtro provincia" formControlName="provincia"></provincia-picker>
        </mat-form-field>

      </div>
    </form>

    <resourceful-index #index [search]="search" [modelService]="comuneService" [deleteFunction]="deleteFunction"
      [columnDefinitions]="columnDefinitions" [editFunction]="editFunction" [selectionMode]="selectionMode"
      (selectedModel)="closeDialogWithSelectedModel($event)" [relations]="relations">
    </resourceful-index>

    <mat-divider></mat-divider>

    <button mat-raised-button color="primary" class="mt-4" (click)="add()">
      Aggiungi un Comune<mat-icon>add</mat-icon>
    </button>

  </div>
  <ng-template #loader>
  </ng-template>
</div>