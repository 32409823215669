import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home/home.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home',
  },
  {
    path: 'user',
    loadChildren: () => import(`./user/user.module`).then(m => m.UserModule),
    data: { title: "Pagina Utente" }
  },
  {
    path: 'admin', loadChildren: () => import(`./admin/admin.module`).then(m => m.AdminModule),
    data: { title: "Pannello Admin" }
  },
  {
    path: 'attivita', loadChildren: () => import(`./attivita/attivita.module`).then(m => m.AttivitaModule),
    data: { title: "Attività" }
  },
  {
    path: 'rendicontazione', loadChildren: () => import(`./rendicontazione/rendicontazione.module`).then(m => m.RendicontazioneModule),
    data: { title: "Rendicontazione" }
  },
  {
    path: '**', component: HomeComponent,
    data: { title: "Home" }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
