import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RicercaUtentiComponent } from './ricerca-utenti.component';
import { CustomMaterialModule } from 'src/app/material-module/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomFormControlsModule } from 'src/app/shared/custom-form-controls/custom-form-controls.module';

@NgModule({
  declarations: [RicercaUtentiComponent],
  imports: [
    CommonModule,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CustomFormControlsModule,
  ],
  exports: [RicercaUtentiComponent],
})
export class RicercaUtentiModule { }
