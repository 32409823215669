import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {
  authService: AuthService;
  constructor(private _authService: AuthService,
    private toast: ToastrService,) {
    this.authService = _authService;
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.authService.isLoggedIn()) {

      const token = this.authService.getAccessToken();

      request = request.clone({
        setHeaders:
        {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request).pipe(catchError(x => this.handleAuthError(x)));
  }

  private handleAuthError(err: HttpErrorResponse) {
    if (err.status === 401 || err.status === 403) {
      this.toast.error("Questa azione non ti è permessa", "Non autorizzato");
    }
    else if (err.status == 429) {
      this.toast.warning("Stai inviando richieste al server troppo frequentemente!");
    }
    else if (err.status >= 500 && err.status < 600) {
      this.toast.error("Errore interno al server");
    }
    return throwError(err);
  }

}
