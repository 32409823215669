<div class="container-fluid">
  <mat-card class="login-card">
    <mat-card-header>
      <mat-card-title>Login Gestionale Eventi</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="form" (keydown.enter)="login()">
        <p>
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput placeholder="email" formControlName="email">
            <mat-icon matSuffix>account_circle</mat-icon>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput placeholder="Password" formControlName="password" type="password">
            <mat-icon matSuffix>lock</mat-icon>
          </mat-form-field>
        </p>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button (click)="login()" color="primary">Login</button>
    </mat-card-actions>
  </mat-card>

</div>