<paginator [tableExporter]="exporter" [hidden]="!pagination" [paginate]="pagination" class="mt-3" #customPaginator
  (emitter)="updateData()">
</paginator>
<div *ngIf="models as loadedModels;else spinner" class="make-table-scrollable-on-mobile">
  <mat-spinner class="my-4" *ngIf="loading"></mat-spinner>

  <div class="my-2" *ngIf="selectionMode">
    <button mat-raised-button color="warn" (click)="selectedRow(null)"> Reset campo </button>

  </div>
  <form [formGroup]="form">
    <table mat-table [dataSource]="loadedModels" matSort (matSortChange)="applySort($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container *ngFor="let column of columnDefinitions">
        <ng-container [matColumnDef]="column.internalName" [ngSwitch]="column.type">
          <th class="column-header" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column.sortDisabled">
            {{column.humanName}}

            <mat-checkbox (click)="$event.stopPropagation();" *ngIf="allowHidingColumns"
              class="column-toggler-checkbox mx-2 mt-2" [formControlName]="column.internalName">
            </mat-checkbox>
          </th>

          <ng-container *ngSwitchCase="'link'">
            <td mat-cell *matCellDef="let model">
              <a [routerLink]="column.href(model)">
                {{column.displayFunction(model)}}
              </a>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'label'">
            <td mat-cell *matCellDef="let model">
              <span>
                {{column.displayFunction(model)}}
              </span>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'string'">
            <td mat-cell *matCellDef="let model">
              <mat-form-field>
                <input type="text" matInput (blur)="inputBlurred(model)" [(ngModel)]="model[column.internalName]"
                  [ngModelOptions]="{standalone: true}">
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'currency'">
            <td mat-cell *matCellDef="let model">
              <mat-form-field>
                <input type="number" matInput (blur)="inputBlurred(model)" [(ngModel)]="model[column.internalName]"
                  [ngModelOptions]="{standalone: true}">
                <span matPrefix> € </span>
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'percentage'">
            <td mat-cell *matCellDef="let model">
              <mat-form-field>
                <input type="number" matInput (blur)="inputBlurred(model)" [(ngModel)]="model[column.internalName]"
                  [ngModelOptions]="{standalone: true}">
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'select'">
            <td mat-cell *matCellDef="let model">
              <mat-form-field>
                <mat-select (blur)="inputBlurred(model)" [(ngModel)]="model[column.internalName]"
                  [ngModelOptions]="{standalone: true}">
                  <mat-option *ngFor="let label of column.customData?.labels;let indexOfelement=index;"
                    [value]="indexOfelement">
                    {{label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'boolean'">
            <td mat-cell *matCellDef="let model">
              <mat-checkbox (change)="inputBlurred(model)" [(ngModel)]="model[column.internalName]"
                [ngModelOptions]="{standalone: true}">
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'date'">
            <td mat-cell *matCellDef="let model">
              <input type="date" (blur)="inputBlurred(model)" [(ngModel)]="model[column.internalName]"
                [ngModelOptions]="{standalone: true}">
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'attivita'">
            <td mat-cell *matCellDef="let model">
              <mat-form-field>
                <attivita-picker (blur)="inputBlurred(model)" [(ngModel)]="model[column.internalName]"
                  [ngModelOptions]="{standalone: true}"></attivita-picker>
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'user'">
            <td mat-cell *matCellDef="let model">
              <mat-form-field>
                <user-picker (blur)="inputBlurred(model)" [(ngModel)]="model[column.internalName]"
                  [ngModelOptions]="{standalone: true}"></user-picker>
              </mat-form-field>
            </td>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Azioni

          <ng-container *ngIf="allowHidingColumns">

            <button mat-icon-button matTooltip="Mostra / Nascondi colonne della tabella"
              [matMenuTriggerFor]="hideColumnsMenu">
              <mat-icon>
                arrow_drop_down
              </mat-icon>
            </button>
            <mat-menu #hideColumnsMenu="matMenu">
              <button mat-menu-item *ngFor="let column of columnDefinitions" (click)="toggleVal(column)">
                <div class="row">
                  <span class="col-9">{{column.humanName}}</span>
                  <span class="col-3 text-right">
                    <mat-checkbox [formControlName]="column.internalName">
                    </mat-checkbox>
                  </span>

                </div>
              </button>
            </mat-menu>

          </ng-container>
        </th>
        <td mat-cell *matCellDef="let model">
          <button *ngIf="editFunction" title="Modifica Anagrafica" mat-icon-button (click)="editFunction(model)">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="deleteFunction" [disabled]="!canDeleteModels(loggedInUser)" title="Elimina" mat-icon-button
            (click)="deleteFunction(model)">
            <mat-icon>delete</mat-icon>
          </button>

          <button *ngIf="allowDuplication" [disabled]="!canDeleteModels(loggedInUser)" title="Duplica" mat-icon-button
            (click)="duplicate(model)">
            <mat-icon>content_copy</mat-icon>
          </button>

        </td>
      </ng-container>
      <ng-container matColumnDef="selectThisRow">
        <th mat-header-cell *matHeaderCellDef> Seleziona </th>
        <td mat-cell *matCellDef="let model">
          <button title="Seleziona questa riga" mat-icon-button (click)="selectedRow(model)">
            <mat-icon>check</mat-icon>
          </button>

        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="namesOfColumnsThatShouldBeDisplayed; sticky: true;"></tr>
      <tr mat-row *matRowDef="let myRowData; columns: namesOfColumnsThatShouldBeDisplayed;"></tr>

    </table>
  </form>
  <div class="my-2" *ngIf="selectionMode">
    <button mat-raised-button color="warn" (click)="selectedRow(null)"> Reset campo </button>

  </div>



  <table class="d-none" matTableExporter #exporter="matTableExporter" mat-table [dataSource]="loadedModels" matSort
    (matSortChange)="applySort($event)">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container *ngFor="let column of columnDefinitions">
      <ng-container [matColumnDef]="column.internalName" [ngSwitch]="column.type">
        <th class="column-header" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column.sortDisabled">
          {{column.humanName}}

          <mat-checkbox (click)="$event.stopPropagation();" *ngIf="allowHidingColumns"
            class="column-toggler-checkbox mx-2 mt-2">
          </mat-checkbox>
        </th>

        <ng-container *ngSwitchCase="'link'">
          <td mat-cell *matCellDef="let model">
            <a [routerLink]="column.href(model)">
              {{column.displayFunction(model)}}
            </a>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'label'">
          <td mat-cell *matCellDef="let model">
            <span>
              {{column.displayFunction(model)}}
            </span>
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'string'">
          <td mat-cell *matCellDef="let model">
            {{model[column.internalName]}}
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'currency'">
          <td mat-cell *matCellDef="let model">
            {{model[column.internalName]}}
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'percentage'">
          <td mat-cell *matCellDef="let model">
            {{model[column.internalName]}}
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'select'">
          <td mat-cell *matCellDef="let model">
            {{column.customData?.labels[model[column.internalName]]}}
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'boolean'">
          <td mat-cell *matCellDef="let model">
            {{model[column.internalName]}}
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'date'">
          <td mat-cell *matCellDef="let model">
            {{model[column.internalName]}}
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'attivita'">
          <td mat-cell *matCellDef="let model">
            {{model[column.internalName]?.humanIdentifier}}
          </td>
        </ng-container>
        <ng-container *ngSwitchCase="'user'">
          <td mat-cell *matCellDef="let model">
            {{model[column.internalName]?.humanIdentifier}}
          </td>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Azioni

        <ng-container *ngIf="allowHidingColumns">

          <button mat-icon-button matTooltip="Mostra / Nascondi colonne della tabella"
            [matMenuTriggerFor]="hideColumnsMenu">
            <mat-icon>
              arrow_drop_down
            </mat-icon>
          </button>
          <mat-menu #hideColumnsMenu="matMenu">
            <button mat-menu-item *ngFor="let column of columnDefinitions" (click)="toggleVal(column)">
              <div class="row">
                <span class="col-9">{{column.humanName}}</span>
                <span class="col-3 text-right">
                  <mat-checkbox>
                  </mat-checkbox>
                </span>

              </div>
            </button>
          </mat-menu>

        </ng-container>
      </th>
      <td mat-cell *matCellDef="let model">
        <button *ngIf="editFunction" title="Modifica Anagrafica" mat-icon-button (click)="editFunction(model)">
          <mat-icon>edit</mat-icon>
        </button>
        <button *ngIf="deleteFunction" [disabled]="!canDeleteModels(loggedInUser)" title="Elimina" mat-icon-button
          (click)="deleteFunction(model)">
          <mat-icon>delete</mat-icon>
        </button>

        <button *ngIf="allowDuplication" [disabled]="!canDeleteModels(loggedInUser)" title="Duplica" mat-icon-button
          (click)="duplicate(model)">
          <mat-icon>content_copy</mat-icon>
        </button>

      </td>
    </ng-container>
    <ng-container matColumnDef="selectThisRow">
      <th mat-header-cell *matHeaderCellDef> Seleziona </th>
      <td mat-cell *matCellDef="let model">
        <button title="Seleziona questa riga" mat-icon-button (click)="selectedRow(model)">
          <mat-icon>check</mat-icon>
        </button>

      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="namesOfColumnsThatShouldBeDisplayed; sticky: true;"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: namesOfColumnsThatShouldBeDisplayed;"></tr>

  </table>

</div>
<ng-template #spinner>
  <mat-spinner>
  </mat-spinner>
</ng-template>