import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

  @Output() logoutEvent = new EventEmitter();


  isLoggedIn: boolean;
  user: User;
  constructor(private authService: AuthService,) {

    authService.loggedIn.subscribe(newValue => {
      this.isLoggedIn = newValue;
    });

    this.user = this.authService.getLoggedInUserFromLocalStorage();

    authService.loggedInUser.subscribe(newValue => {
      this.user = new User().deserialize(newValue);
    });


  }



  public emitLogoutEvent() {
    this.logoutEvent.emit();
  }

}
