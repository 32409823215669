import { Comune } from './comune';
import { Model } from './model';
/**
 * Classe "model" comune, ma con anche via, civico e comune, già deserializzato.
 * Ha anche un getter "fullAddress" che restituisce una stringa in formato standardizzato.
 */
export abstract class ModelWithAddress extends Model {

  via: string;
  civico: string;

  comune: Comune;
  cap: number;

  deserialize(input: any): Model {
    let retV: any = new Model();
    retV = super.deserialize(input)

    if (input?.comune) {
      retV.comune = new Comune().deserialize(input.comune);
    }

    return retV;
  }

  /**
   * Restituisce l'via con via, civico, comune e provincia tra parentesi (ove presenti)
   */
  get fullAddress() {
    let res = "";

    if (this.via)
      res += this.via + ' ';
    if (this.civico)
      res += this.civico + ' ';
    if (this.comune)
      res += this.comune.nome + ' ';
    if (this.comune?.provincia)
      res += `(${this.comune.provincia.nome})` + ' ';

    return res;
  }

  /**
   * Usato per la ubicazione e descrizione del rischio nella perizia universale.
   */
  get ubicazione() {
    let risultato = "In ";

    if (this?.comune?.provincia?.nome?.toLowerCase()?.trim() === this?.comune?.nome?.toLowerCase()?.trim())
      risultato += this.comune?.nome;
    else
      risultato += `Provincia di  ${this.comune?.provincia?.nome} - Comune di  ${this.comune?.nome}`;

    if (this.viaCivico)
      risultato += ` - ${this.viaCivico}`;

    return risultato;
  }


  get viaCivico() {
    let res = "";

    if (this.via)
      res += this.via;
    if (this.civico)
      res += ', ' + this.civico;

    return res;
  }

} 