<paginator [tableExporter]="exporter" [hidden]="!pagination" [paginate]="pagination" class="mt-3" #customPaginator
  (emitter)="updateData()">
</paginator>
<div *ngIf="models as loadedModels;else spinner" class="make-table-scrollable-on-mobile">
  <mat-spinner class="my-4" *ngIf="loading"></mat-spinner>

  <div class="my-2" *ngIf="selectionMode">
    <button mat-raised-button color="warn" (click)="selectedRow(null)"> Reset campo </button>

  </div>
  <form [formGroup]="form">
    <table matTableExporter #exporter="matTableExporter" mat-table [dataSource]="loadedModels" matSort
      (matSortChange)="applySort($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container *ngFor="let column of columnDefinitions">
        <ng-container [matColumnDef]="column.internalName" [ngSwitch]="column.type">
          <th class="column-header" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column.sortDisabled">
            {{column.humanName}}

            <mat-checkbox (click)="$event.stopPropagation();" *ngIf="allowHidingColumns"
              class="column-toggler-checkbox mx-2 mt-2" [formControlName]="column.internalName">
            </mat-checkbox>
          </th>
          <ng-container *ngSwitchCase="'string'">
            <td mat-cell *matCellDef="let model"> {{column.displayFunction(model)}}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'currency'">
            <td mat-cell *matCellDef="let model"> {{column.displayFunction(model) | currency}}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'boolean'">
            <td mat-cell *matCellDef="let model">
              <mat-icon>
                {{column.displayFunction(model)?'done':'not_interested'}}
              </mat-icon>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'button'">
            <td mat-cell *matCellDef="let model">
              <button mat-icon-button (click)="column.onClick(model)">
                <mat-icon>{{column.internalName}}</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'date'">
            <td mat-cell *matCellDef="let model">
              {{column.displayFunction(model)}}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'datetime'">
            <td mat-cell *matCellDef="let model">
              {{column.displayFunction(model)}}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'datetimefromnow'">
            <td mat-cell *matCellDef="let model">
              {{column.displayFunction(model)}}
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'link'">
            <td mat-cell *matCellDef="let model">
              <a [routerLink]="column.href(model)">
                {{column.displayFunction(model)}}
              </a>
            </td>
          </ng-container>
          <ng-container *ngSwitchCase="'percentage'">
            <td mat-cell *matCellDef="let model">
              {{column.displayFunction(model) || 0}}%
            </td>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Azioni

          <ng-container *ngIf="allowHidingColumns">

            <button mat-icon-button matTooltip="Mostra / Nascondi colonne della tabella"
              [matMenuTriggerFor]="hideColumnsMenu">
              <mat-icon>
                arrow_drop_down
              </mat-icon>
            </button>
            <mat-menu #hideColumnsMenu="matMenu">
              <button mat-menu-item *ngFor="let column of columnDefinitions" (click)="toggleVal(column)">
                <div class="row">
                  <span class="col-9">{{column.humanName}}</span>
                  <span class="col-3 text-right">
                    <mat-checkbox [formControlName]="column.internalName">
                    </mat-checkbox>
                  </span>

                </div>
              </button>
            </mat-menu>

          </ng-container>
        </th>
        <td mat-cell *matCellDef="let model">
          <button *ngIf="editFunction" title="Modifica Anagrafica" mat-icon-button (click)="editFunction(model)">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="deleteFunction" [disabled]="!canDeleteModels(loggedInUser)" title="Elimina" mat-icon-button
            (click)="deleteFunction(model)">
            <mat-icon>delete</mat-icon>
          </button>

        </td>
      </ng-container>
      <ng-container matColumnDef="selectThisRow">
        <th mat-header-cell *matHeaderCellDef> Seleziona </th>
        <td mat-cell *matCellDef="let model">
          <button title="Seleziona questa riga" mat-icon-button (click)="selectedRow(model)">
            <mat-icon>check</mat-icon>
          </button>

        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="namesOfColumnsThatShouldBeDisplayed; sticky: true;"></tr>
      <tr mat-row *matRowDef="let myRowData; columns: namesOfColumnsThatShouldBeDisplayed;"></tr>

    </table>
  </form>
  <div class="my-2" *ngIf="selectionMode">
    <button mat-raised-button color="warn" (click)="selectedRow(null)"> Reset campo </button>

  </div>
</div>
<ng-template #spinner>
  <mat-spinner>
  </mat-spinner>
</ng-template>