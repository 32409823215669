import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from '../material-module/confirm-dialog/confirm-dialog.component';
import { dataTableColumnDefinition } from '../shared/resourceful-index/dataTableColumnDefinition';
import { ResourcefulIndexComponent } from '../shared/resourceful-index/resourceful-index/resourceful-index.component';
import { Attivita } from '../models/attivita'
import { AttivitaService } from '../services/attivita.service';
import { Model } from '../models/model';
@Component({
  selector: 'app-attivita',
  templateUrl: './attivita.component.html',
  styleUrls: ['./attivita.component.scss']
})
export class AttivitaComponent implements OnInit {

  totalPrev = "-";

  totalCons = "-";

  tipi: string[] = ["EVENTS", "PROMOTION"];

  headingText = 'Attività';
  stati: string[] = ["Da confermare", "Confermato", "Cancellato", "Terminato"]

  relations: string[] = ['referente1', 'referente2'];

  @ViewChild('index') index: ResourcefulIndexComponent;

  selectionMode: boolean = false;

  loading: boolean = true;

  search: any = {};

  columnDefinitions: dataTableColumnDefinition[] = [
    new dataTableColumnDefinition(
      {
        humanName: 'ID',
        internalName: 'id',
        type: 'link',
        href: (model) => model.id,
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Tipo',
        internalName: 'tipo',

      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Anno',
        internalName: 'anno',

      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'CDC',
        internalName: 'cdc',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Tipo cs',
        internalName: 'tipo_cs',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Stato evento',
        internalName: 'stato',
        type: 'select',
        customData: {
          labels: ["Da confermare", "Confermato", "Cancellato", "Terminato"],
        }
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Luogo svolgimento',
        internalName: 'luogo_svolgimento',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Mese',
        internalName: 'mese',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Inizio',
        internalName: 'start',
        type: 'date',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Fine',
        internalName: 'end',
        type: 'date',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Titolo',
        internalName: 'titolo',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Cliente',
        internalName: 'cliente',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Preventivo',
        internalName: 'preventivo',
        type: 'currency',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Consuntivo',
        internalName: 'consuntivo',
        type: 'currency',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Referente1',
        internalName: 'referente1',
        type: 'user',

      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Referente2',
        internalName: 'referente2',
        type: 'user',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Numero pax delegati o faculty',
        internalName: 'numero_pax_delegati_o_faculty',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'ID ECM',
        internalName: 'id_ecm',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Stato ECM',
        internalName: 'stato_ecm',
        type: 'select',
        customData: {
          labels: ["Da accreditare ", "Accreditato ", "Da pagare ", "Pagato ", "Da rapportare ", "Rapportato"]
        }
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Tasse ministeriali',
        internalName: 'tasse_ministeriali',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Crediti',
        internalName: 'crediti',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Scadenza pagamento crediti',
        internalName: 'scadenza_pagamento_crediti',
        type: 'date',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Report',
        internalName: 'report',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Attestati',
        internalName: 'attestati',
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Data spedizione',
        internalName: 'data_spedizione',
        type: 'date',
      }
    ),
  ];

  constructor(public attivitaService: AttivitaService,
    public dialog: MatDialog,
    private toast: ToastrService,
    @Optional() private dialogRef: MatDialogRef<AttivitaComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,) {
    if (this.data?.selectionMode) {
      this.selectionMode = true;
      this.headingText = 'Seleziona un attivita';
    }
  }


  form: FormGroup;

  async ngOnInit() {
    this.form = this.fb.group({
      titolo: [],
      tipo: [],
      referente: [],
      start: [],
      stato: [],
    });

    this.form.valueChanges.subscribe(newValues => this.search = newValues);

    this.loading = false;
  }

  closeDialogWithSelectedModel(model) {
    this.dialogRef?.close(model);
  }

  async updateDataSource() {
    await this.index?.updateData();
  }


  deleteFunction = async (attivita: Attivita) => {
    try {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: `Sei sicuro di voler eliminare la attivita ${attivita.humanIdentifier}?` } });
      dialogRef.afterClosed().subscribe(async data => {
        if (data) {
          await this.attivitaService.delete(attivita);
          this.toast.success('Attivita eliminata.');
          this.updateDataSource();
        }
      });
    } catch (e) {
      console.error(e);
      this.toast.error("Eliminazione attivita fallita.", "Errore!");
    }
  }


  add() {
    this.handleCreation(new Attivita());
  }

  async handleCreation(attivita?: Attivita) {
    if (attivita) {
      try {
        await this.attivitaService.create(attivita)
        this.toast.success('Attivita creata!')
      }
      catch (e) {
        this.toast.error('Creazione attivita fallita.', 'Errore!');
      }
      finally {
        this.updateDataSource();
      }

    }
  }

  async duplicateModel({ model, times }: { model: Attivita, times: number }) {
    await this.attivitaService.clone(model, times);
    this.updateDataSource();
  }

  async updateModel(model: Attivita) {
    if (model.id)
      await this.attivitaService.edit(model);
    else {
      await this.attivitaService.create(model);
      this.updateDataSource();
    }
  }

  public onWorksheetLoad(models) {
    let total_preventivo = 0;
    let total_consuntivo = 0;
    for (let j = 0; j < models.length; j++) {
      total_consuntivo += models[j].consuntivo
      total_preventivo += models[j].preventivo
    }

    this.totalPrev = total_preventivo.toFixed(2)
    this.totalCons = total_consuntivo.toFixed(2)
  }
}
