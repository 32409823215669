import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Options } from 'mat-table-exporter';
import { Model } from 'src/app/models/model';
import { ResourcefulService } from 'src/app/services/resourcefulService';

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.less']
})
export class PaginatorComponent implements OnInit {
  @Input() tableExporter;

  @Input() paginate = true;
  objectsPerPage: number = 20;

  lastPage: number;
  currentPage: number = 1;

  objectsCount: number;

  //this output event emitter fires when the index component that is using this paginator should update
  //its data
  @Output() emitter: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if (!this.paginate)
      this.objectsPerPage = 99999;
  }

  updatePaginationNumbers(correctObjectCounts?: number) {

    if (correctObjectCounts || correctObjectCounts === 0) {
      this.objectsCount = correctObjectCounts;
    }

    this.lastPage = Math.ceil(this.objectsCount / this.objectsPerPage);
    if (this.lastPage === NaN) {
      this.lastPage = 0;
    }
  }


  async changePage(desiredPage: number) {
    if (0 < desiredPage && desiredPage <= this.lastPage) {
      this.currentPage = desiredPage;
      await this.tellParentToUpdateData();
    }
  }

  searchChanged() {
    this.currentPage = 1;
    this.tellParentToUpdateData();
  }

  tellParentToUpdateData() {
    this.emitter.emit('updateData');
  }
  getExportOptions(): Options {
    return {
      fileName: generateId()
    }
  }

}

function dec2hex(dec) {
  return dec.toString(16).padStart(2, "0")
}

function generateId(len = 20) {
  var arr = new Uint8Array(len / 2 | 0)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}