import { ModelWithAddress } from './model-with-address';
/**
 * Classe "ModelWithAddress" comune, ma con anche tel, fax ed email.
 */
export abstract class ModelWithAddressAndContactInfo extends ModelWithAddress {
  tel: string;
  fax: string;
  cell: string;
  email: string;
  IBAN?: string;
}
