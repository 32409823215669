import { Component, Input, OnInit } from '@angular/core';

export class SidebarRoute {
  constructor(public name, public path) {

  }
}



@Component({
  selector: 'custom-sidebar',
  templateUrl: './custom-sidebar.component.html',
  styleUrls: ['./custom-sidebar.component.scss']
})
export class CustomSidebarComponent implements OnInit {

  /** Wether or not the sidebar starts out as opened */
  @Input() opened: boolean = true;

  /** The routes displayed in the sidebar (basically display name and href) */
  @Input() routes: SidebarRoute[];

  @Input() heading = "Heading mancante";

  /** Position sidebar left (start) or right (end) */
  @Input() position = 'start';

  /** Wether the content is provided through ng-content or through a router outlet */
  @Input() contentMode = 'router-outlet'

  constructor() {
  }

  ngOnInit() {
  }


}
