import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseFormControl } from '../base-form-control/base-form-control';
import { Model } from '../../../models/model';


@Component({
  host: {
    '(click)': 'open_if_target_was_not_link($event)',
    '(focusout)': 'onTouched()',
  },
  providers: [
    { provide: MatFormFieldControl, useExisting: ExternalSelectorComponent },
  ],
  selector: 'external-selector',
  styleUrls: ['./external-selector.component.scss'],
  templateUrl: './external-selector.component.html',
})
export class ExternalSelectorComponent extends BaseFormControl implements MatFormFieldControl<Model>, OnDestroy, AfterViewInit {

  /**
   * Wether or not the user should be allowed to click on a blue arrow that sends him to a new page
   * Set this to false if you want to prevent users from clicking away from an unfinished form
   */
  @Input() allowExternalLink = true;

  @Input() defaultSearch = {};

  @Input()
  get externalEntityObserver(): Observable<Model> {
    throw `Missing externalEntityObserver function.\n
     Sample:\n
     return this.dialog.open(ProvinceComponent, { data: { selectionMode: true } }).afterClosed();`;
  };

  @Input()
  get value() {
    return this.parts.value;
  }

  @Input() readonly = false;


  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @ViewChild('id', { read: ElementRef })
  idRef: ElementRef<HTMLInputElement>;
  @ViewChild('humanIdentifier', { read: ElementRef })
  humanIdentifierRef: ElementRef<HTMLInputElement>;



  controlType: string = 'external-selector';

  @HostBinding()
  id = `${this.controlType}-${++ExternalSelectorComponent.nextId}`;

  get empty(): boolean {
    const n = this.parts.value;

    return !n.id && !n.humanIdentifier;

  }

  onContainerClick(event: MouseEvent): void {
    this.focusMonitor.focusVia(this.idRef.nativeElement, 'mouse');
  }

  get errorState(): boolean {
    this.changeDetection.detectChanges();
    return (this.parts.dirty || this.parts.touched) && !this.parts?.valid;
  }

  parts: FormGroup = this.fb.group({
    id: [''],
    humanIdentifier: ['',],
  });

  registerOnChange(onChange: (value: Model | null) => void): void {
    this.parts.valueChanges.pipe(
      takeUntil(this.destroy),
    ).subscribe(onChange);
  }

  openSelector() {
    if (!this.readonly) {
      this.externalEntityObserver.subscribe(res => { //this is tipically an afterClosed of a matDialog.
        if (res !== undefined) {
          this.writeValue(res);
          this.blur.next(); //close selector is kinda like blur
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
  }


  setDisabledState(shouldDisable: boolean): void {
    if (shouldDisable) {
      this.parts.disable();
    } else {
      this.parts.enable();
    }

    this.disabled = shouldDisable;
  }

  writeValue(value?: Model): void {
    if (value === null) {
      this.parts.reset();
    }
    else {
      let newValue = {};
      for (let formControlKey of Object.keys(this?.parts?.controls))
        newValue[formControlKey] = value[formControlKey];

      if (this.parts?.contains('original_model')) {
        newValue['original_model'] = value;
      }

      this.parts.patchValue(newValue);
    }

    this.stateChanges.next();

  }

  /**
   * Given the model id, return the URL to view the model details.
   */
  getRoute?: ((modelId: any) => string);

  @Output()
  ngModelChange = new EventEmitter<{ id: number, humanIdentifier: string }>();

  @Input() set ngModel(value: Model) {
    this.parts?.patchValue({ id: value?.id, humanIdentifier: value?.humanIdentifier }, { emitEvent: false });
  };

  @Output() blur = new EventEmitter<void>();

  ngAfterViewInit(): void {
    this.parts.valueChanges.subscribe(v => {
      this.ngModelChange.next(v);
    });
    this.focusMonitor.monitor(this.elementRef.nativeElement, true)
      .subscribe(focusOrigin => {
        let wasFocused = this.focused;
        this.focused = !!focusOrigin;
        if (wasFocused && !this.focused) {
          console.log("calling blur next because focus monitor.");
          this.blur.next();
        }
      });
  }


  open_if_target_was_not_link($event: MouseEvent) {
    let target = $event?.target;
    if (!target || !target['href'])
      this.openSelector();
  }
}
