<h4 mat-dialog-title>
  <span *ngIf="!user?.id;else editHeader">
    Creazione Nuovo Utente
  </span>
  <ng-template #editHeader>
    Modifica Utente {{user?.humanIdentifier}}
  </ng-template>
</h4>

<div *ngIf="!loading">
  <form [formGroup]="form" class="mt-4 row" (keydown.enter)="edit()">
    <mat-form-field>
      <input matInput placeholder="Email" formControlName="email">
      <mat-icon matSuffix>account_circle</mat-icon>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Password" formControlName="password" type="password">
      <mat-icon matSuffix>lock</mat-icon>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Qualifica" formControlName="qualifica">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Titolo" formControlName="titolo">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Ruoli</mat-label>
      <mat-select multiple placeholder="Ruoli" formControlName="role_ids">
        <mat-option *ngFor="let ruolo of ruoli" [value]="ruolo.id">{{ruolo.nome}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Nome" formControlName="nome">
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Cognome" formControlName="cognome">
    </mat-form-field>
    <mat-form-field>

      <mat-label>
        Via / piazza
      </mat-label>
      <input matInput formControlName="via">
    </mat-form-field>

    <mat-form-field>
      <mat-label>
        N. civico
      </mat-label>
      <input matInput formControlName="civico">
    </mat-form-field>

    <mat-form-field>
      <mat-label>
        Comune
      </mat-label>
      <comune-picker formControlName="comune"></comune-picker>
    </mat-form-field>
    <mat-form-field>
      <mat-label>
        Telefono
      </mat-label>
      <input matInput formControlName="tel">
    </mat-form-field>
    <mat-form-field>
      <mat-label>
        Cellulare
      </mat-label>
      <input matInput formControlName="cell">
    </mat-form-field>
    <mat-form-field>
      <mat-label>
        Fax
      </mat-label>
      <input matInput formControlName="fax">
    </mat-form-field>
  </form>
  <button mat-raised-button (click)="edit()" color="primary">Salva</button>
</div>
<ng-template #loader>
  <mat-spinner>
  </mat-spinner>
</ng-template>