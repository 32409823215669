import { CommonModule, DecimalPipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ListPropertyPipe } from './list-property.pipe';
import { StripHTMLPipe } from './striphtml.pipe';
import { TruncatePipe } from './truncate.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { DateRangeFilterPipe } from './date-range-filter.pipe';
import { ToNumberPipe } from './decimal-pipe';

@NgModule({
  declarations: [
    StripHTMLPipe,
    TruncatePipe,
    ListPropertyPipe,
    DateFormatPipe, 
    DateRangeFilterPipe,
    ToNumberPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    StripHTMLPipe,
    TruncatePipe,
    ListPropertyPipe,
    DateFormatPipe,
    DateRangeFilterPipe,
    ToNumberPipe,
  ]
})
export class PipeModule {

  static forRoot(): ModuleWithProviders<PipeModule> {
    return {
      ngModule: PipeModule,
      providers: [],
    };
  }
}
