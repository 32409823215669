import { Model } from './model';
import { Provincia } from './provincia';

export class Regione extends Model {
  static apiUrl: string = "regioni";

  nome: string;
  province: Provincia[];

  deserialize(input: any): Regione {
    let retV: Regione = new Regione();

    retV = super.deserialize(input) as Regione;

    if (input?.province) {
      retV.province = input.province.map(p => new Provincia().deserialize(p));
    }

    return retV;
  }

}
