import { FocusMonitor } from '@angular/cdk/a11y'; import { ElementRef, Optional, Self, ChangeDetectorRef, Input, HostBinding, Directive } from '@angular/core'; import { NgControl, FormBuilder, ControlValueAccessor } from '@angular/forms'; import { AutofillMonitor } from '@angular/cdk/text-field'; import { MatDialog } from '@angular/material/dialog';
import { Subject, Observable } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { map, startWith } from 'rxjs/operators';

@Directive()
export abstract class BaseFormControl implements ControlValueAccessor {

  constructor(
    protected focusMonitor: FocusMonitor,
    protected elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl | null,
    protected autofillMonitor: AutofillMonitor,
    protected dialog: MatDialog,
    protected fb: FormBuilder,
    protected changeDetection: ChangeDetectorRef,
  ) {
    if (ngControl) {
      // Set the value accessor directly (instead of providing
      // NG_VALUE_ACCESSOR) to avoid running into a circular import
      this.ngControl.valueAccessor = this;
      ngControl.valueAccessor = this;
    }

  }

  abstract writeValue(obj: any): void;


  stateChanges: Subject<void> = new Subject();

  abstract registerOnChange(onChange: (value: any | null) => void): void

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }


  abstract onContainerClick(event: MouseEvent): void

  abstract controlType: string;
  onTouched(): void { }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  @HostBinding('attr.aria-describedby')
  describedBy: string = '';

  autofilled: boolean = false;

  static nextId: number = 0;

  protected _disabled: boolean = false;
  protected _focused: boolean = false;
  protected _placeholder: string = '';
  protected _required: boolean = false;
  protected destroy: Subject<void> = new Subject();

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  get focused(): boolean {
    return this._focused;
  }
  set focused(value: boolean) {
    this._focused = value;
    this.stateChanges.next();
  }


  protected observeAutofill(ref: ElementRef): Observable<boolean> {
    return this.autofillMonitor.monitor(ref)
      .pipe(map(event => event.isAutofilled))
      .pipe(startWith(false));
  }
}
