import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

export class BaseEditForm {
  form: FormGroup;
  public dialogRef: MatDialogRef<any>;

  async edit() {

    this.form.markAllAsTouched(); // mark all form controls and groups as touched to trigger display of validation errors.

    if (this.form?.valid) {
      let res = await this.handleSuccessfulSubmit();
      this.dialogRef?.close(res ?? this.form.value);
    }

    else {
      this.warnNotValid();
    }
  }

  protected warnNotValid() {
    this['toast']?.warning('Il form non è valido.');
    console.error(this.form?.errors);
    console.log(findInvalidControls(this.form))
  }

  //dummy successful submit method
  handleSuccessfulSubmit(): Promise<any> {
    return Promise.resolve(undefined);
  };

}
function findInvalidControls(f: FormGroup) {
  if (!f)
    return [];
  const invalid = [];
  const controls = f.controls;
  for (const name in controls) {
    if (controls[name].invalid) {
      invalid.push(name);
    }
  }
  return invalid;
}

