import { Comune } from './comune';
import { Model } from './model';
import { Regione } from './regione';

export class Provincia extends Model {
    static apiUrl: string = "province";

    nome: string;
    codice: string;
    regione: Regione;
    comuni: Comune[];

    deserialize(input: any): Provincia {
        let retV: Provincia = new Provincia();

        retV = super.deserialize(input) as Provincia;

        if (input?.comuni) {
            retV.comuni = input.comuni.map(m => new Comune().deserialize(m));
        }

        if (input?.regione) {
            retV.regione = new Regione().deserialize(input.regione);
        }

        return retV;
    }

}
