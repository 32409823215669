import { AfterViewInit, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/material-module/confirm-dialog/confirm-dialog.component';
import { Regione } from 'src/app/models/regione';
import { RegioneService } from 'src/app/services/regione.service';
import { dataTableColumnDefinition } from 'src/app/shared/resourceful-index/dataTableColumnDefinition';
import { ResourcefulIndexComponent } from 'src/app/shared/resourceful-index/resourceful-index/resourceful-index.component';
import { EditComponent } from './dialogs/edit/edit.component';

@Component({
  selector: 'regioni',
  templateUrl: './regioni.component.html',
  styleUrls: ['./regioni.component.less']
})
export class RegioniComponent implements OnInit, AfterViewInit {

  headingText: string = 'Regioni';

  relations: string[] = [];

  @ViewChild('index') index: ResourcefulIndexComponent;

  selectionMode: boolean = false;

  loading: boolean = true;

  search: any;

  columnDefinitions: dataTableColumnDefinition[] = [
    new dataTableColumnDefinition(
      {
        humanName: 'ID',
        internalName: 'id',

      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Nome',
        internalName: 'nome',

      }
    ),
  ];
  constructor(public regioneService: RegioneService,
    public dialog: MatDialog,
    private toast: ToastrService,
    @Optional() private dialogRef: MatDialogRef<RegioniComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,) {
    if (this.data?.selectionMode) {
      this.selectionMode = true;
      this.headingText = 'Seleziona una regione';
    }
  }

  form: FormGroup;
  ngOnInit() {
    this.form = this.fb.group({
      nome: [],
    });

    this.form.valueChanges.subscribe(newValues => this.search = newValues);

  }

  async ngAfterViewInit(): Promise<void> {
    await this.updateDataSource();
    this.loading = false;
  }

  closeDialogWithSelectedModel(model) {
    this.dialogRef?.close(model);
  }

  async updateDataSource() {
    await this.index?.updateData();
  }


  deleteFunction = async (regione: Regione) => {
    try {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: `Sei sicuro di voler eliminare la regione ${regione.nome}?` } });
      dialogRef.afterClosed().subscribe(async data => {
        if (data) {
          await this.regioneService.delete(regione);
          this.toast.success('Regione eliminata.');
          this.updateDataSource();
        }
      });
    } catch (e) {
      console.error(e);
      this.toast.error("Eliminazione regione fallita.", "Errore!");
    }
  }



  editFunction = async (regione: Regione) => {
    try {
      let dialogRef = this.dialog.open(EditComponent, { data: regione });
      dialogRef.afterClosed().subscribe(async (data: Regione) => {
        if (data) {

          Object.assign(regione, data);

          await this.regioneService.edit(regione);

          this.toast.success('Regione modificata');
          this.updateDataSource();

        }
      });

    } catch (e) {
      console.error(e);
      this.toast.error("Modifica regione fallita.", "Errore!");
    }
  }


  add() {
    let addDialogRef = this.dialog.open(EditComponent, { data: this.search });
    addDialogRef.afterClosed().subscribe(data => this.handleCreation(data));
  }

  async handleCreation(regione?: Regione) {
    if (regione) {
      try {
        await this.regioneService.create(regione)
        this.toast.success('Regione creata!')
      }
      catch (e) {
        this.toast.error('Creazione regione fallita.', 'Errore!');
      }
      finally {
        this.updateDataSource();
      }

    }
  }



}
