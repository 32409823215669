<div class="container-fluid pb-3 pt-2">

    <h4>
        {{headingText}}
    </h4>

    <div *ngIf="!loading; else loader">

        <form *ngIf="form" class="row" [formGroup]="form">
            <div class="col-4">
                <mat-form-field>
                    <buffered-input placeholder="Filtra per titolo" formControlName="titolo"></buffered-input>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label>Filtra per tipo</mat-label>
                    <mat-select formControlName="tipo">
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let tipo of tipi" [value]="tipo">
                            {{tipo}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <user-picker placeholder="Filtra per referente" formControlName="referente"></user-picker>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label>Filtra per stato</mat-label>
                    <mat-select formControlName="stato">
                        <mat-option>Tutti</mat-option>
                        <mat-option [value]="i" *ngFor="let stato of stati; index as i">
                            {{stato}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label>Intervallo temporale</mat-label>
                    <date-range-picker formControlName="start"></date-range-picker>
                    <!--                  <mat-date-range-input [formGroup]="form" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="DA">
                        <input matEndDate formControlName="end" placeholder="A">
                    </mat-date-range-input>
                    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker> -->

                    <!-- <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error> -->
                </mat-form-field>
            </div>
        </form>

        <resourceful-worksheet #index [search]="search" [modelService]="attivitaService"
            (newModelsLoaded)="onWorksheetLoad($event)" [deleteFunction]=" deleteFunction"
            [columnDefinitions]="columnDefinitions" (edited)="updateModel($event)" (duplicated)="duplicateModel($event)"
            [selectionMode]="selectionMode" (selectedModel)="closeDialogWithSelectedModel($event)"
            [relations]="relations">
        </resourceful-worksheet>

        <div class="row justify-content-between">
            <div class="col-4">
                <button mat-raised-button color="primary" class="mt-4" (click)="add()">
                    Aggiungi un attività<mat-icon>add</mat-icon>
                </button>
            </div>
            <div class="col-3 font-weight-bold align-self-center">
                Totale preventivi: {{totalPrev}} €
            </div>
            <div class="col-3 font-weight-bold align-self-center">
                Totale consuntivi: {{totalCons}} €
            </div>
        </div>

    </div>
    <ng-template #loader>
    </ng-template>
</div>