import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ExternalSelectorComponent } from './external-selector.component';
import { CustomMaterialModule } from 'src/app/material-module/material.module';
import { ExternalMorphSelectorComponent } from './external-morph-selector/external-morph-selector.component';


@NgModule({
  declarations: [ExternalSelectorComponent, ExternalMorphSelectorComponent],
  exports: [ExternalSelectorComponent, RouterModule],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CustomMaterialModule,
  ],
})
export class ExternalSelectorModule { }
