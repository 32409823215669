import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomMaterialModule } from 'src/app/material-module/material.module';
import { CustomFormControlsModule } from 'src/app/shared/custom-form-controls/custom-form-controls.module';
import { EntityPickersModule } from 'src/app/shared/custom-form-controls/entity-pickers/entity-pickers.module';
import { ResourcefulIndexModule } from 'src/app/shared/resourceful-index/resourceful-index.module';
import { ComuniComponent } from './comuni.component';
import { EditComponent } from './dialogs/edit/edit.component';
import { ProvinceModule } from './province/province.module';



@NgModule({
  declarations:
    [
      ComuniComponent,
      EditComponent,
    ],
  imports: [
    CommonModule,
    CustomMaterialModule,
    ReactiveFormsModule,
    ResourcefulIndexModule,
    ProvinceModule,
    CustomFormControlsModule,
    EntityPickersModule,
  ],
  exports: [
    ComuniComponent,
    EditComponent,
  ],

})
export class ComuniModule { }
