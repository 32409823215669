import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { ComuniComponent } from './comuni/comuni.component';
import { UsersComponent } from './users/users.component';
import { CollegamentiComponent } from './collegamenti/collegamenti.component';

const routes: Routes = [
  {
    path: '', component: AdminPanelComponent,
    children: [
      {
        path: 'users', component: UsersComponent,
        data: { title: 'Utenti' }
      },
      {
        path: 'comuni', component: ComuniComponent,
        data: { title: 'Comuni' }
      },
      {
        path: 'collegamenti', component: CollegamentiComponent,
        data: { title: 'Collegamenti' }
      },
      {
        path: '', component: UsersComponent,
        data: { title: 'Utenti' }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
