import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { AnnoPickerComponent } from './anno-picker/anno-picker.component';
import { CustomMaterialModule } from '../../../material-module/material.module';


@NgModule({
  declarations: [DatePickerComponent, AnnoPickerComponent, DateRangePickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CustomMaterialModule,
  ],
  exports: [DatePickerComponent, AnnoPickerComponent, DateRangePickerComponent],
})
export class TimeFormControlsModule { }
