import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Ruolo } from 'src/app/models/ruolo';
import { RuoloService } from 'src/app/services/ruolo.service';

@Component({
  selector: 'ricerca-utenti',
  templateUrl: './ricerca-utenti.component.html',
  styleUrls: ['./ricerca-utenti.component.scss']
})
export class RicercaUtentiComponent implements OnInit {

  @Input() search: any;
  @Output() searchChange = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private ruoloService: RuoloService,
  ) { }

  form: FormGroup;
  ruoli: Ruolo[];

  async ngOnInit() {
    this.ruoli = await this.ruoloService.getAll();

    this.form = this.fb.group({
      nome: [this.search?.nome],
      cognome: [this.search?.cognome],
      via: [this.search?.via],
      civico: [this.search?.civico],
      comune: [this.search?.comune],
      tel: [this.search?.tel],
      fax: [this.search?.fax],
      cell: [this.search?.cell],
      email: [this.search?.email],
      ruoli: [this.search?.ruoli],
    });

    this.form.valueChanges.subscribe(() => this.searchChange.emit(this.form.value));

  }

}
