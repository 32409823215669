import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateRangeFilter'
})
export class DateRangeFilterPipe implements PipeTransform {

  transform(row: any, f1: Date, f2?: Date): any {

    const risultatoFiltro = [];
    let date1 = new Date(f1);
    let date2 = new Date(f2);
    // se è inferiore alla data finale
    if (f1 >= f2 || f1 == null) {
      return row;
    }
    // se l'argomento della data di fine non è valido, viene impostato sulla data corrente
    if (f2 == null) {
      f2 = new Date();
    }
    // se entrambi gli array sono corretti, viene creato il nuovo array
    for (const filteredRow of row) {
        let a = new Date(filteredRow.data);
        console.log(a);

        if (a > date1 && a <= date2) {
          console.log("asd", filteredRow);
          risultatoFiltro.push(filteredRow);
        }
    }
    return risultatoFiltro;
    
  }

}
