import * as moment from 'moment';

export class Model {

    static apiUrl: string;

    id: number;

    created_at: moment.Moment;
    updated_at: moment.Moment;
    deleted_at: moment.Moment;

    deserialize(input: any): Model {

        /**Prepare input for deserialization.
         * This is needed when input is an object that has the property humanIdentifier defined.
         * This can happen as a result of some defaults, for example when somebody searches for
         * something, doesn't find it and so adds a new entry in the resourceful index.
         * The search parameters are passed in the form dialog as default values,
         * so if the search parameters had an external value picked through an entity picker,
         * that object would have a "humanIdentifier" property, and its deserialization would throw
         * the error:Error: Uncaught (in promise): TypeError: Cannot set property humanIdentifier of [object Object] which has only a getter
         * TypeError: Cannot set property humanIdentifier of [object Object] which has only a getter
         * at Function.assign (<anonymous>)
         * at Comune.deserialize (model.ts:14)
         * at Comune.deserialize (comune.ts:19)
         * at User.deserialize (model-with-address.ts:19)
         * at User.deserialize (user.ts:42)
         * at UsersComponent.<anonymous> (users.component.ts:192)
         */
        if (input?.humanIdentifier)
            delete input.humanIdentifier;

        /**
         * Actually do the work now, after having prepared input for deserialization.
         */
        Object.assign(this, input);
        if (input) {
            if (input?.created_at) {
                this.created_at = moment(input.created_at);
            }
            if (input?.updated_at) {
                this.updated_at = moment(input.updated_at);
            }
            if (input?.deleted_at) {
                this.deleted_at = moment(input.deleted_at);
            }
        }
        return this;
    }

    /**
     * Restituisce la stringa che gli umani possono capire (tipicamente non l'id, ma un nome o qualcosa del genere)
     * come default restituisce l'ID, le sottoclassi devono personalizzare questa implementazione.
     */
    public getHumanIdentifier() {
        if (this['nome'])
            return this['nome'];

        if (this.id)
            return `${this.id}`;

        return '';
    }

    /**
     * Funzione solo per convenienza nella definizione delle colonne delle tabelle resourceful index.
     * così invece che dover definire una display function nella column definition possiamo semplicemente passare "humanIdentifier" come 
     * internal name, e così nascondere la chiamata "scomoda" della funzione getHumanIdentifier.
     */
    get humanIdentifier() {
        return this.getHumanIdentifier();
    }

    static getDefaultSortKey() {
        return 'updated_at';
    }
    static getDefaultSortDirection() {
        return 'desc';
    }
}
