import { AfterViewInit, Component, ElementRef, forwardRef, HostBinding, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { BaseFormControl } from '../base-form-control/base-form-control';



@Component({
  providers: [
    { provide: MatFormFieldControl, useExisting: forwardRef(() => BufferedInputComponent) },
  ],
  selector: 'buffered-input',
  styleUrls: ['./buffered-input.component.less'],
  templateUrl: './buffered-input.component.html',
})
export class BufferedInputComponent extends BaseFormControl implements AfterViewInit, OnDestroy {

  @Input() type: string = "text";

  @Input()
  get value(): any {
    return this.form.controls.main.value;
  }

  set value(value: any) {
    this.form.controls.main.setValue(value);
    this.bufferedInput = value;
    this.stateChanges.next();
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @ViewChild('main', { read: ElementRef })
  main: ElementRef<HTMLInputElement>;


  controlType: string = 'buffered-input';

  @HostBinding()
  id = `${this.controlType}-${++BufferedInputComponent.nextId}`;

  get empty(): boolean {
    const n: any = this.form.value;

    return !n.main && n.main != "0";

  }

  onContainerClick(event: MouseEvent): void {
    {
      if ((event.target as Element).tagName.toLowerCase() !== 'input') {
        this.focusMonitor.focusVia(this.main.nativeElement, 'mouse');
      }
    }
  }

  get errorState(): boolean {
    this.changeDetection.detectChanges();
    return (this.form.dirty || this.form.touched) && !this.form?.valid;
  }

  form: FormGroup = this.fb.group({
    main: [''],
  });

  registerOnChange(onChange: (value: any | null) => void): void {
    this.form.valueChanges.pipe(
      takeUntil(this.destroy),
      map(values => values.main),
    ).subscribe(onChange);
  }



  modelChanged: Subject<string> = new Subject<string>();

  ngAfterViewInit(): void {
    this.focusMonitor.monitor(this.elementRef.nativeElement, true)
      .subscribe(focusOrigin => {
        this.focused = !!focusOrigin;

      });

    combineLatest(
      this.observeAutofill(this.main),
    ).pipe(
      map(autofills => autofills.some(autofilled => autofilled)),
      takeUntil(this.destroy),
    ).subscribe(autofilled => this.autofilled = autofilled);


    this.modelChanged.pipe(
      debounceTime(400), // wait 300ms after the last event before emitting last event
      distinctUntilChanged() // only emit if value is different from previous value
    )
      .subscribe(model => {
        this.form.controls.main.setValue(model);
      });

  }


  changed(text: string) {
    this.modelChanged.next(text);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
    this.autofillMonitor.stopMonitoring(this.main);
  }


  setDisabledState(shouldDisable: boolean): void {
    if (shouldDisable) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this.disabled = shouldDisable;
  }

  writeValue(value: any | null): void {
    this.form.controls.main.setValue(value, { emitEvent: false });

    this.bufferedInput = value;
    this.stateChanges.next();
  }


  bufferedInput; //useful when parent form gives an initial form value so we have to sync the displayed input with the hidden one


}
