import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomMaterialModule } from 'src/app/material-module/material.module';
import { CustomFormControlsModule } from 'src/app/shared/custom-form-controls/custom-form-controls.module';
import { ResourcefulIndexModule } from 'src/app/shared/resourceful-index/resourceful-index.module';
import { EditComponent } from './dialogs/edit/edit.component';
import { RegioniComponent } from './regioni.component';



@NgModule({
  declarations: [RegioniComponent, EditComponent],
  imports: [
    CommonModule,
    CustomMaterialModule,
    ReactiveFormsModule,
    ResourcefulIndexModule,
    CustomFormControlsModule,
  ]
})
export class RegioniModule { }
