import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Provincia } from 'src/app/models/provincia';

@Component({
  selector: 'edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class EditComponent implements OnInit {

  provincia: Provincia;
  form: FormGroup;

  constructor(private fb: FormBuilder,
    private toast: ToastrService,
    public dialogRef: MatDialogRef<EditComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Provincia,) {
    this.provincia = data;
  }

  ngOnInit() {

    this.form = this.fb.group({
      nome: [this.provincia?.nome, Validators.required],
      codice: [this.provincia?.codice, Validators.required],
      regione: [this.provincia?.regione, Validators.required],
    });

  }

  edit() {
    if (this.form.valid)
      this.dialogRef.close(this.form.value);
    else this.toast.warning('Il form non è valido.');
  }
}

