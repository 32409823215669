import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Regione } from '../models/regione';
import { ResourcefulService } from './resourcefulService';



@Injectable({
    providedIn: 'root'
})
export class RegioneService extends ResourcefulService<Regione>{
    constructor(private httpClient: HttpClient) {
        super(httpClient, Regione);
    }
}
