import { Model } from './model';

export class Collegamento extends Model {
  static apiUrl: string = "collegamenti";

  nome: string;
  ordinale: number;
  url: string;

  deserialize(input: any): Collegamento {
    let retV: Collegamento = new Collegamento();
    retV = super.deserialize(input) as Collegamento;
    return retV;
  }

}
