import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mat-clearable-input',
  templateUrl: './mat-clearable-input.component.html',
  styleUrls: ['./mat-clearable-input.component.scss']
})
export class MatClearableInputComponent implements OnInit {

  //Questo componente non è utilizzato, perchè purtroppo in angular non sono supportate ng-content "innestate"
  //se fossero supportate, questo componente sarebbe utile per avere tutti i form control con una crocetta che permette
  //di fare "clear" del campo.

  constructor() { }

  ngOnInit(): void {
  }

}
