import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Ruolo } from 'src/app/models/ruolo';
import { RuoloService } from 'src/app/services/ruolo.service';
import { BaseEditForm } from 'src/app/shared/baseEditForm';
import { User } from '../../../../models/user';

@Component({
  selector: 'edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent extends BaseEditForm implements OnInit {

  loading: boolean = true;
  user: User;
  ruoli: Ruolo[];

  form: FormGroup;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditComponent>,
    public ruoloService: RuoloService,
    public toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: User) {
    super();
    this.user = data;
  }

  async ngOnInit() {

    this.ruoli = await this.ruoloService.getAll();

    this.loading = false;
    this.form = this.fb.group({
      email: [this.user?.email, Validators.required],
      password: [],
      qualifica: [this.user?.qualifica],
      titolo: [this.user?.titolo],
      nome: [this.user?.nome, Validators.required],
      cognome: [this.user?.cognome],
      role_ids: [this.user?.ruoli?.map(r => r.id)],

      via: [this.user?.via],
      civico: [this.user?.civico],
      comune: [this.user?.comune],
      tel: [this.user?.tel],
      fax: [this.user?.fax],
      cell: [this.user?.cell],
    });

  }
}

