import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CustomMaterialModule } from 'src/app/material-module/material.module';
import { PaginatorComponent } from './paginator/paginator.component';
import { ResourcefulIndexComponent } from './resourceful-index/resourceful-index.component';
import { ResourcefulWorksheetComponent } from './resourceful-worksheet/resourceful-worksheet.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { CustomFormControlsModule } from '../custom-form-controls/custom-form-controls.module';


@NgModule({
  declarations: [PaginatorComponent, ResourcefulIndexComponent, ResourcefulWorksheetComponent],
  imports: [
    CommonModule,
    CustomMaterialModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    MatTableExporterModule,
    CustomFormControlsModule,
  ],
  exports: [PaginatorComponent, ResourcefulIndexComponent, ResourcefulWorksheetComponent]
})
export class ResourcefulIndexModule { }
