import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})

export class LoginComponent implements OnInit {


  form: FormGroup;

  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService) {

  }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

  }

  async login() {
    const val = this.form.value;
    try {
      await this.authService.login({ email: val.email, password: val.password });

      this.toastr.success('Login Riuscito!', 'Successo!');
      this.router.navigateByUrl('/home');
    }
    catch (e) {
      console.error(e);
      this.toastr.error('Login fallito.', 'Errore!');
    }

  }
}
