<div class="row" *ngIf="form" [formGroup]="form">
  <div class="col-10">
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="Da">
      <input matEndDate formControlName="end" placeholder="A">
    </mat-date-range-input>
    <mat-date-range-picker #picker></mat-date-range-picker>

  </div>
  <div class="col-2">
    <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
  </div>
</div>