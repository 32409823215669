<div class="row no-gutters">
  <div class="col-11" *ngIf="parts" [formGroup]="parts">
    <input #id type="hidden" formControlName="id">

    <input class="w-100" #humanIdentifier readonly formControlName="humanIdentifier" (keydown.space)="openSelector()">

  </div>
  <div class="col-1 text-right" *ngIf="allowExternalLink && getRoute && parts?.value?.id">
    <a [routerLink]="getRoute(parts?.value?.id)" [href]="getRoute(parts?.value?.id)">
      ↗
    </a>
  </div>
</div>