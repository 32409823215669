import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService implements ErrorHandler {
  private toast: ToastrService;

  //timer used to avoid printing errors too many times in a second if they keep coming
  timer: any;

  /* we have to inject the toastr service in this weird way.
  i tried just injecting it normally and it was undefined.
  Searched stackoverflow and this was the solution.
  https://stackoverflow.com/questions/41585863/angular2-injecting-services-in-custom-errorhandler
  */
  constructor(private injector: Injector, private ngZone: NgZone) {
    setTimeout(() => this.toast = injector.get(ToastrService));
  }

  //Clear the lates setTimeout and set a new one
  handleError(error) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.printError(error), 100);
  }

  printError(error) {

    let message = error.toString();

    if (!message.includes || message.includes("ExpressionChangedAfterItHasBeenCheckedError"))
      return console.error(error); //display error to user only if it's not ExpressionChangedAfterItHasBeenCheckedError error

    //need to run in ngZone or change detection doesn't run and message isn't displayed
    this.ngZone.run(() => {
      console.error(error);
      this.toast.error("Si è verificato un errore.", "Errore!");
      //Invoke debugging functionality. See:
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/debugger
      debugger;
    });
  }
}
