import { Model } from './model';
import { User } from './user';

export class Attivita extends Model {
  static apiUrl: string = "attivita";

  public nome: string;
  public tipo: string
  anno: string
  cdc: string
  tipo_cs: string
  stato: number;
  luogo_svolgimento: string
  mese: string
  start: string
  end: string
  titolo: string
  cliente: string
  preventivo: string
  consuntivo: string
  referente1_id: string
  referente2_id: string
  numero_pax_delegati_o_faculty: string
  numero_pax_accreditati_ecm: string
  id_ecm: string
  stato_ecm: number;
  tasse_ministeriali: string
  crediti: string
  scadenza_pagamento_crediti: string
  report: string
  attestati: string
  data_spedizione: string
  referente1: User;
  referente2: User;

  getHumanIdentifier() {
    return this.titolo;
  }

  deserialize(input: any): Attivita {
    let retV: Attivita = new Attivita();
    retV = super.deserialize(input) as Attivita;
    retV.tipo = input['tipo']

    if (input?.referente1) {
      retV.referente1 = new User().deserialize(input.referente1);
    }

    if (input?.referente2) {
      retV.referente2 = new User().deserialize(input.referente2);
    }


    return retV;
  }

}
