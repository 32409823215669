type dataTableColumnType = 'label' | 'currency' | 'boolean' | 'button' | 'string' | 'list' | 'date' | 'datetime' | 'datetimefromnow' | 'datefromnow' | 'link' | 'percentage' | 'attivita' | 'user' | 'select';

export class dataTableColumnDefinition {
  humanName: string;
  internalName: string;
  customData: any;
  sortDisabled?: boolean;
  type?: dataTableColumnType

  displayFunction?: (value: any) => string

  //only used in type = button
  onClick?: (value: any) => any;

  //only used in type = link
  href: (value: any) => string


  //method that given the model returns the thing that we need to display (that will then later be formatted by the display function)
  thingToBeDisplayed: (value: any) => any;
  constructor(params: {
    humanName: string, internalName: string, sortDisabled?: boolean, type?: dataTableColumnType,
    displayFunction?: (value: any) => string, onClick?: (value: any) => any, href?: (value: any) => string,
    thingToBeDisplayed?: (value: any) => any,
    customData?: any,
  }
  ) {
    this.thingToBeDisplayed = params.thingToBeDisplayed || ((value) => value[this.internalName]);
    this.humanName = params.humanName;
    this.internalName = params.internalName;
    this.sortDisabled = params.sortDisabled;
    this.type = params.type || 'string';
    this.displayFunction = params.displayFunction || ((val) => this.thingToBeDisplayed(val));
    this.customData = params.customData;
    //If type is date and no custom function was specified, use default format L
    if (!params.displayFunction) {
      if (this.type == 'date')
        this.displayFunction = ((val) => this.thingToBeDisplayed(val)?.format('L'));

      //if type is datetime, include time in format.
      if (this.type == 'datetime')
        this.displayFunction = ((val) => this.thingToBeDisplayed(val)?.format('L HH:mm'));
    }

    this.onClick = params.onClick;
    //href calculating function. default is just #
    this.href = params.href || (() => '#');

  }

}
