import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Regione } from 'src/app/models/regione';

@Component({
  selector: 'edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class EditComponent implements OnInit {

  regione: Regione;
  form: FormGroup;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Regione,
    private toast: ToastrService,) {
    this.regione = data;
  }

  ngOnInit() {

    this.form = this.fb.group({
      nome: [this.regione?.nome, Validators.required],

    });

  }

  edit() {
    if (this.form.valid)
      this.dialogRef.close(this.form.value);
    else this.toast.warning('Il form non è valido.');
  }


}

