<div class="container-fluid pb-3 pt-2">
  <h4 class="mb-2">{{headingText}}</h4>
  <div *ngIf="!loading; else loader">
    <form *ngIf="form" [formGroup]="form">
      <mat-form-field>
        <buffered-input placeholder="Filtro" formControlName="nome"></buffered-input>
      </mat-form-field>
    </form>

    <resourceful-index #index [search]="search" [modelService]="collegamentoService" [deleteFunction]="deleteFunction"
      [columnDefinitions]="columnDefinitions" [editFunction]="editFunction" [selectionMode]="selectionMode"
      (selectedModel)="closeDialogWithSelectedModel($event)" [relations]="relations">
    </resourceful-index>

    <button mat-raised-button color="primary" class="mt-4" (click)="add()">
      Aggiungi uno collegamento<mat-icon>add</mat-icon>
    </button>

  </div>
  <ng-template #loader>
    <mat-spinner></mat-spinner>
  </ng-template>
</div>