<h4 mat-dialog-title>
  <span *ngIf="!data;else editHeader">
    Creazione Nuova Regione
  </span>
  <ng-template #editHeader>
    Modifica Regione {{data.nome}}
  </ng-template>
</h4>
<form *ngIf="form" [formGroup]="form" class="mt-4" (keydown.enter)="edit()">

  <div>
    <mat-form-field>
      <mat-label>Nome</mat-label>
      <input matInput formControlName="nome">
      <mat-icon matSuffix>nome</mat-icon>
    </mat-form-field>
  </div>
</form>
<button mat-raised-button (click)="edit()" color="primary">Salva</button>
