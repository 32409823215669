import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { BaseEditForm } from 'src/app/shared/baseEditForm';
import { Comune } from '../../../../models/comune';

@Component({
  selector: 'edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class EditComponent extends BaseEditForm implements OnInit {

  comune: Comune;
  form: FormGroup;
  constructor(private fb: FormBuilder,
    private toast: ToastrService,
    public dialogRef: MatDialogRef<EditComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Comune,) {
    super();
    this.comune = data;
  }

  ngOnInit() {

    this.form = this.fb.group({
      nome: [this.comune?.nome, Validators.required],
      cap: [this.comune?.cap, Validators.required],
      provincia: [this.comune?.provincia, Validators.required],
    });
  }

}

