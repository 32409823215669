import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { ResourcefulService } from './resourcefulService';



@Injectable({
  providedIn: 'root'
})
export class UserService extends ResourcefulService<User>{
  constructor(private httpClient: HttpClient) {
    super(httpClient, User);
  }
}



