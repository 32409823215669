import { HttpClient } from '@angular/common/http';
import { map, takeUntil } from 'rxjs/operators';
import { Model } from '../models/model';
import { PaginationInfo } from '../models/paginationInfo';
import { baseUrl } from './api-config';
import { Subject } from 'rxjs';




//see
//https://stackoverflow.com/questions/41089854/typescript-access-static-attribute-of-generic-type
//if you're asking yourself why i have a type parameter in generic definition but i also use a type
// parameter in constructor
export class ResourcefulService<Class extends Model> {

  modelClass;
  baseUrl = baseUrl;
  _httpClient: HttpClient;

  //kind of like static since we provide this service in root, so it's a singleton.
  count: number;

  constructor(private httpClientSuper: HttpClient, modelClass) {
    this.modelClass = modelClass;
    this._httpClient = httpClientSuper;
  }

  protected ngUnsubscribe = new Subject<void>();


  //default: No relationships are loaded and pagination is not requested.
  public getAll(relations: string[] = [], paginationInfo: PaginationInfo = { desiresPagination: false }): Promise<Class[]> {
    this.ngUnsubscribe.next();
    //see https://developer.mozilla.org/it/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment
    let { sortedColumn, sortDirection, search, requestedPage, objectsPerPage, desiresPagination } = paginationInfo;

    //bounds ( can't request page "-1"!)
    const normalizedRequestedPage = requestedPage ? Math.max(0, requestedPage) : "";
    const normalizedObjectsPerPage = objectsPerPage ? Math.max(1, objectsPerPage) : "";
    //build params with some simple rules
    let params = {
      "relations[]": relations,
      "sortedColumn": sortedColumn,
      "sortDirection": sortDirection,
      //need ""+ because they have to be strings.
      "objectsPerPage": "" + normalizedObjectsPerPage,
      "requestedPage": "" + normalizedRequestedPage,
      "desiresPagination": "" + desiresPagination,
      //this will be used in server side pagination. It doesn't have anything to do with
      //client side filtering in mat datatables.
      "search": JSON.stringify(search),
    };
    //for each of those params, perform simple boolean check. if is nan or undefined make it empty.
    //nan is written as a string because all param values are strings, and so NaN will actually be "NaN"
    //make sure to not discard valid 0 numbers (they would be falsy)
    Object.keys(params).forEach((key) => {
      if (params[key] !== 0 && (!params[key] || params[key] == "NaN" || params[key] == undefined)) {
        params[key] = "";
      }
    });

    if (!params["sortedColumn"]) {
      params["sortedColumn"] = this.modelClass.getDefaultSortKey();
      params["sortDirection"] = this.modelClass.getDefaultSortDirection();
    }

    let obsrvbl = this._httpClient.get<{ data: Model[], count?: number }>(`${baseUrl}/${this.modelClass.apiUrl}`, { params })

    return obsrvbl.pipe(

      takeUntil(this.ngUnsubscribe),

      map(response => {
        if (response["count"] || response["count"] === 0) {
          this.count = response["count"];
        }
        return response;
      }),

      map(response => response["data"].map(
        model => { const m = new this.modelClass().deserialize(model); /*console.log(model, m);*/ return m; }
      ))
    ).toPromise();
  }


  public getOne(id: number, relations: string[] = []): Promise<Class> {

    const params = {
      "relations[]": relations
    };

    return this._httpClient.get<{ data: Model }>(`${baseUrl}/${this.modelClass.apiUrl}/${id}`, { params })
      .pipe(
        map(response => new this.modelClass().deserialize(response.data))
      )
      .toPromise();
  }

  public create(model: Class): Promise<Class> {
    this.count++;
    return this._httpClient.post<Model>(`${baseUrl}/${this.modelClass.apiUrl}`, model)
      .pipe(
        map(response => new this.modelClass().deserialize(response))
      )
      .toPromise();
  }

  public clone(model: Class, times: number): Promise<Class[]> {
    this.count += times;
    return this._httpClient.post<Object[]>(`${baseUrl}/${this.modelClass.apiUrl}/${model.id}/clone`, { times })
      .pipe(
        map(response => response.map(v => new this.modelClass().deserialize(v)))
      )
      .toPromise();

  }

  public edit(model: Class): Promise<any> {
    return this._httpClient.patch(`${baseUrl}/${this.modelClass.apiUrl}/${model.id}`, model).toPromise();
  }
  public delete(model: Class): Promise<any> {
    this.count--;
    return this._httpClient.delete(`${baseUrl}/${this.modelClass.apiUrl}/${model.id}`).toPromise();
  }

}

