import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Collegamento } from 'src/app/models/collegamento';
import { BaseEditForm } from 'src/app/shared/baseEditForm';

@Component({
  selector: 'edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditCollegamentoComponent extends BaseEditForm implements OnInit {

  constructor(private fb: FormBuilder,
    private toast: ToastrService,
    public dialogRef: MatDialogRef<EditCollegamentoComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: Collegamento,) {
    super();
  }

  ngOnInit() {

    this.form = this.fb.group({
      nome: [this.data?.nome, Validators.required],
      url: [this.data?.url],
      ordinale: [this.data?.ordinale], 
    });
  }

}

