import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listProperty'
})
export class ListPropertyPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {

    const emptyArrayMessage = args[2] ? args[2] : "Nessuno"
    try {
      //default property: "Name".
      const propertyToBeListed = args[0] ? args[0] : "nome"
      //default separator: ", "
      const separator = args[1] ? args[1] : ", "
      //default return value if array has no values:
      if (value.length > 0)
        return value.map(v => v[propertyToBeListed]).join(separator);
      return emptyArrayMessage
    }
    catch (e) {
      return emptyArrayMessage;
    }
  }

}
