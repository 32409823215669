import { Component, OnInit, ComponentFactoryResolver, Injector, ElementRef, Optional, Self, ChangeDetectorRef } from '@angular/core';
import { ExternalSelectorComponent } from '../external-selector.component';
import { FocusMonitor } from '@angular/cdk/a11y';
import { NgControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { MatDialog } from '@angular/material/dialog';
import { Model } from 'src/app/models/model';

export class ExternalMorphEntity extends Model {
  constructor(
    public id: number = null,
    public nome: string = '',
    public type: string = '',
  ) {
    super();
  }
}

@Component({
  selector: 'external-morph-selector',
  templateUrl: '../external-selector.component.html',
  styleUrls: ['./external-morph-selector.component.scss']
})
export class ExternalMorphSelectorComponent extends ExternalSelectorComponent {

  parts: FormGroup = this.fb.group({
    id: ['',],
    humanIdentifier: ['',],
    type: ['',],
  });

  constructor(protected resolver: ComponentFactoryResolver,
    protected injector: Injector,
    protected focusMonitor: FocusMonitor,
    protected elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl | null,
    protected autofillMonitor: AutofillMonitor,
    protected dialog: MatDialog,
    protected fb: FormBuilder,
    protected changeDetection: ChangeDetectorRef,) {
    super(focusMonitor, elementRef, ngControl, autofillMonitor, dialog, fb, changeDetection);

  }



  writeValue(value: ExternalMorphEntity | null): void {

    value = value || new ExternalMorphEntity();
    const type = value.type;
    const id = value.id;
    let humanIdentifier = value.humanIdentifier;
    this.parts.patchValue({ id, humanIdentifier, type });

    this.stateChanges.next();
  }

}
