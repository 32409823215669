import { Component, forwardRef, Input } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Observable } from 'rxjs';
import { ProvinceComponent } from 'src/app/admin/comuni/province/province.component';
import { ExternalSelectorComponent } from '../../external-selector/external-selector.component';

@Component({
  providers: [
    { provide: MatFormFieldControl, useExisting: forwardRef(() => ProvinciaPickerComponent) },
  ],
  selector: 'provincia-picker',
  templateUrl: '../../external-selector/external-selector.component.html',
  styleUrls: ['../../external-selector/external-selector.component.scss']
})
export class ProvinciaPickerComponent extends ExternalSelectorComponent {

  @Input()
  get externalEntityObserver(): Observable<any> {
    return this.dialog.open(ProvinceComponent, { data: { selectionMode: true } }).afterClosed();
  };


}
