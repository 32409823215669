import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExternalSelectorModule } from '../external-selector/external-selector.module';
import { ProvinciaPickerComponent } from './provincia-picker/provincia-picker.component';
import { RegionePickerComponent } from './region-picker/region-picker.component';
import { UserPickerComponent } from './user-picker/user-picker.component';
import { ComunePickerComponent } from './comune-picker/comune-picker.component';
import { AttivitaPickerComponent } from './attivita-picker/attivita-picker.component';

const entityPickers = [
  ProvinciaPickerComponent,
  RegionePickerComponent,
  UserPickerComponent,
  ComunePickerComponent,
  AttivitaPickerComponent,
]
@NgModule({
  declarations: entityPickers,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ExternalSelectorModule,
  ],
  exports: entityPickers,
})
export class EntityPickersModule { }
