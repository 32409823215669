import { Component, Input, OnInit } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidebarRoute } from 'src/app/material-module/custom-sidebar/custom-sidebar.component';
import { CollegamentoService } from '../../services/collegamento.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private collegamentiService: CollegamentoService) { }

  ngOnInit(): void {
    let sidebarPromise = this.collegamentiService.getAll()

    this.sidebarRoutes$ = from(sidebarPromise).pipe(
      map(collegamenti => collegamenti.sort((a, b) => a.ordinale - b.ordinale).map(collegamento => new SidebarRoute(collegamento?.nome, collegamento?.url)))
    );
  }

  sidebarRoutes$: Observable<SidebarRoute[]>

}
