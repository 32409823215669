import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomMaterialModule } from '../material-module/material.module';
import { PipeModule } from '../pipe/pipe.module';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { AdminRoutingModule } from './admin-routing.module';
import { ComuniModule } from './comuni/comuni.module';
import { UsersModule } from './users/users.module';
import { CollegamentiModule } from './collegamenti/collegamenti.module';

@NgModule({
  declarations: [
    AdminPanelComponent,
  ],
  exports: [AdminPanelComponent,
    AdminRoutingModule],
  imports: [
    CommonModule,
    AdminRoutingModule,
    UsersModule,
    ComuniModule,
    CustomMaterialModule,
    PipeModule,
    ReactiveFormsModule,
    FormsModule,
    CollegamentiModule,
  ],

})
export class AdminModule { }
