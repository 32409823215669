import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BufferedInputModule } from './buffered-input/buffered-input.module';
import { ExternalSelectorModule } from './external-selector/external-selector.module';
import { SignatureInputModule } from './signature-input/signature-input.module';
import { CustomMaterialModule } from 'src/app/material-module/material.module';
import { EntityPickersModule } from './entity-pickers/entity-pickers.module';
import { TimeFormControlsModule } from './time-form-controls/time-form-controls.module';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NumberRangePickerComponent } from './number-range-picker/number-range-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [NumberRangePickerComponent],
  imports: [
    CommonModule,
    ExternalSelectorModule,
    BufferedInputModule,
    EntityPickersModule,
    CustomMaterialModule,
    MaterialFileInputModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ExternalSelectorModule,
    BufferedInputModule,
    TimeFormControlsModule,
    EntityPickersModule,
    SignatureInputModule,
    MaterialFileInputModule,
    NumberRangePickerComponent,
  ],
})
export class CustomFormControlsModule { }
