import { AfterViewInit, Component, ElementRef, forwardRef, HostBinding, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BaseFormControl } from '../../base-form-control/base-form-control';
import { MatDatepicker } from '@angular/material/datepicker';



@Component({
  providers: [
    { provide: MatFormFieldControl, useExisting: forwardRef(() => DatePickerComponent) },
  ],
  selector: 'date-picker',
  styleUrls: ['./date-picker.component.less'],
  templateUrl: './date-picker.component.html',
})
export class DatePickerComponent extends BaseFormControl implements AfterViewInit, OnDestroy {


  @Input()
  get value(): any {
    return this.form.controls.main.value;
  }

  set value(value: any) {
    this.form.controls.main.setValue(value);
    this.stateChanges.next();
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @ViewChild('main', { read: ElementRef })
  main: ElementRef<HTMLInputElement>;

  @ViewChild(MatDatepicker)
  datePicker: MatDatepicker<any>;


  controlType: string = 'date-picker';

  @HostBinding()
  id = `${this.controlType}-${++DatePickerComponent.nextId}`;

  get empty(): boolean {
    const n = this.form.value;

    return !n.main && n.main != "";

  }

  onContainerClick(event: MouseEvent): void {
    {
      if ((event.target as Element).tagName.toLowerCase() !== 'input') {
        this.focusMonitor.focusVia(this.main.nativeElement, 'mouse');
      }
    }
  }

  get errorState(): boolean {
    this.changeDetection.detectChanges();
    return (this.form.dirty || this.form.touched) && !this.form?.valid;
  }

  form: FormGroup = this.fb.group({
    main: [''],
  });

  registerOnChange(onChange: (value: any | null) => void): void {
    this.form.valueChanges.pipe(
      takeUntil(this.destroy),
      map(values => values.main),
    ).subscribe(onChange);
  }



  modelChanged: Subject<string> = new Subject<string>();

  ngAfterViewInit(): void {
    this.focusMonitor.monitor(this.elementRef.nativeElement, true)
      .subscribe(focusOrigin => {
        this.focused = !!focusOrigin;

      });

    combineLatest(
      this.observeAutofill(this.main),
    ).pipe(
      map(autofills => autofills.some(autofilled => autofilled)),
      takeUntil(this.destroy),
    ).subscribe(autofilled => this.autofilled = autofilled);


  }


  changed(text: string) {
    this.modelChanged.next(text);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
    this.autofillMonitor.stopMonitoring(this.main);
  }


  setDisabledState(shouldDisable: boolean): void {
    if (shouldDisable) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this.disabled = shouldDisable;
  }

  writeValue(value: any | null): void {
    this.form.controls.main.setValue(value, { emitEvent: false });

    this.stateChanges.next();
  }

  closeOnBlur = true;

  onFocus() {
    this.datePicker.open();
    this.closeOnBlur = false;

    /**
     * Workaround to make sure that input field is focused and accepts input.
     * https://stackoverflow.com/a/53609529/9988983
     * this is needed because otherwise the datePicker gets focused when opened!
     */
    setTimeout(() => {
      this.main.nativeElement.focus();
      this.closeOnBlur = true;
    });
  }
  onFocusOut(event: FocusEvent) {
    if (this.isTargetCalendar(event))
      return;
    if (this.closeOnBlur) this.datePicker.close();
  }

  isTargetCalendar(event: FocusEvent) {
    let className = '';

    let element = event?.relatedTarget as HTMLElement;

    className = element?.className;

    return className?.includes('mat-calendar')
  }
}
