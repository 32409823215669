import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomMaterialModule } from 'src/app/material-module/material.module';
import { PipeModule } from 'src/app/pipe/pipe.module';
import { CustomFormControlsModule } from 'src/app/shared/custom-form-controls/custom-form-controls.module';
import { ResourcefulIndexModule } from 'src/app/shared/resourceful-index/resourceful-index.module';
import { EditComponent } from './dialogs/edit/edit.component';
import { UsersComponent } from './users.component';
import { RicercaUtentiModule } from './search/ricerca-utenti.module';


@NgModule({
  declarations:
    [
      UsersComponent,
      EditComponent,
    ],
  imports: [
    CommonModule,
    CustomMaterialModule,
    ReactiveFormsModule,
    PipeModule,
    ResourcefulIndexModule,
    CustomFormControlsModule,
    RicercaUtentiModule,
  ],
  exports: [
    UsersComponent,
    EditComponent,
  ],

})
export class UsersModule { }
