import { Component, forwardRef, Input } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Observable } from 'rxjs';
import { ExternalSelectorComponent } from '../../external-selector/external-selector.component';
import { AttivitaComponent } from '../../../../attivita/attivita.component';
@Component({
  providers: [
    { provide: MatFormFieldControl, useExisting: forwardRef(() => AttivitaPickerComponent) },
  ],
  selector: 'attivita-picker',
  templateUrl: '../../external-selector/external-selector.component.html',
  styleUrls: ['../../external-selector/external-selector.component.scss']
})
export class AttivitaPickerComponent extends ExternalSelectorComponent {

  @Input()
  get externalEntityObserver(): Observable<any> {
    return this.dialog.open(AttivitaComponent, {
      data: {
        selectionMode: true,
        defaultSearch: this.defaultSearch,
      }
    }).afterClosed();
  };

}
