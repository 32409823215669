import { AfterViewInit, Component, ElementRef, forwardRef, HostBinding, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BaseFormControl } from '../../base-form-control/base-form-control';
import { MatDatepicker } from '@angular/material/datepicker';



@Component({
  providers: [
    { provide: MatFormFieldControl, useExisting: forwardRef(() => DateRangePickerComponent) },
  ],
  selector: 'date-range-picker',
  styleUrls: ['./date-range-picker.component.scss'],
  templateUrl: './date-range-picker.component.html',
})
export class DateRangePickerComponent extends BaseFormControl implements AfterViewInit, OnDestroy {


  @Input()
  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.patchValue(value);
    this.stateChanges.next();
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @ViewChild('picker', { read: ElementRef })
  picker: ElementRef<HTMLInputElement>;

  @ViewChild('datePicker', { read: ElementRef })
  datePicker: ElementRef<MatDatepicker<any>>;


  controlType: string = 'date-range-picker';

  @HostBinding()
  id = `${this.controlType}-${++DateRangePickerComponent.nextId}`;

  get empty(): boolean {
    const n = this.form.value;

    return !n.start && !n.end;

  }

  onContainerClick(event: MouseEvent): void {
    {
      if ((event.target as Element).tagName.toLowerCase() !== 'input') {
        this.focusMonitor.focusVia(this.picker.nativeElement, 'mouse');
      }
    }
  }

  get errorState(): boolean {
    this.changeDetection.detectChanges();
    return (this.form.dirty || this.form.touched) && !this.form?.valid;
  }

  form = this.fb.group({
    start: [''],
    end: [''],
  });

  registerOnChange(onChange: (value: any | null) => void): void {
    this.form.valueChanges.pipe(
      takeUntil(this.destroy),
      map(val => {
        val.type = 'date-range';
        return val;
      })
    ).subscribe(onChange);
  }




  ngAfterViewInit(): void {
    this.focusMonitor.monitor(this.elementRef.nativeElement, true)
      .subscribe(focusOrigin => {
        this.focused = !!focusOrigin;

      });

    combineLatest(
      this.observeAutofill(this.picker),
    ).pipe(
      map(autofills => autofills.some(autofilled => autofilled)),
      takeUntil(this.destroy),
    ).subscribe(autofilled => this.autofilled = autofilled);


  }


  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
    this.autofillMonitor.stopMonitoring(this.picker);
  }


  setDisabledState(shouldDisable: boolean): void {
    if (shouldDisable) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this.disabled = shouldDisable;
  }

  writeValue(value: any | null): void {
    if (!value)
      return this.form.reset();
    this.form.patchValue(value, { emitEvent: false });

    this.stateChanges.next();
  }

}
