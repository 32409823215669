import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { filter, map } from 'rxjs/operators';
import { User } from './models/user';
import { AuthService } from './services/auth.service';
@Component({
  selector: 'root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  isLoggedIn: boolean;
  user: User;

  loading: boolean = false;


  constructor(private authService: AuthService,
    private title: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService) {
    //global locale setting
    moment.locale('it');

    authService.loggedIn.subscribe(newValue => {
      this.isLoggedIn = newValue;
    });

    this.user = this.authService.getLoggedInUserFromLocalStorage();

    authService.loggedInUser.subscribe(newValue => {
      this.user = newValue;
    });


    this.setupProgressBar();


    this.setupDynamicTitle(router);

  }

  /**
   * This function observes events from router and sets the title of the html document based on the data of the route.
   *
   * @param router the router that gives events that we subscribe to
   */
  private setupDynamicTitle(router: Router) {
    router.events.pipe(filter((ev) => ev instanceof NavigationEnd), map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild)
          route = route.firstChild;
        return route;
      })).subscribe((activatedRoute: ActivatedRoute) => {
        this.title.setTitle(activatedRoute.snapshot.data['title'] || 'Gestionale Eventi');
      });
  }

  /**
   * Makes sure that when a user navigates to a route, the mat progress bar gets activated and displayed to the user.
   */
  private setupProgressBar() {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }




  public logout() {
    this.authService.logout();
    this.toastr.success('Logout Riuscito!', 'Successo!');
  }

}
