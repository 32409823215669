<form *ngIf="form" class="row" [formGroup]="form">
    <mat-form-field>
        <mat-label>
            Nome
        </mat-label>
        <buffered-input formControlName="nome"></buffered-input>
    </mat-form-field>
    <mat-form-field>
        <mat-label>
            Cognome
        </mat-label>
        <buffered-input formControlName="cognome"></buffered-input>
    </mat-form-field> 
    <mat-form-field>
        <mat-label>
            Via / piazza
        </mat-label>
        <buffered-input formControlName="via"></buffered-input>
    </mat-form-field>

    <mat-form-field>
        <mat-label>
            N. civico
        </mat-label>
        <buffered-input formControlName="civico"></buffered-input>
    </mat-form-field>

    <mat-form-field>
        <mat-label>
            Comune
        </mat-label>
        <comune-picker formControlName="comune"></comune-picker>
    </mat-form-field>
    <mat-form-field>
        <mat-label>
            Telefono
        </mat-label>
        <buffered-input formControlName="tel"></buffered-input>
    </mat-form-field>
    <mat-form-field>
        <mat-label>
            Cellulare
        </mat-label>
        <buffered-input formControlName="cell"></buffered-input>
    </mat-form-field>
    <mat-form-field>
        <mat-label>
            Fax
        </mat-label>
        <buffered-input formControlName="fax"></buffered-input>
    </mat-form-field>
    <mat-form-field>
        <mat-label>
            Email
        </mat-label>
        <buffered-input formControlName="email"></buffered-input>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Ruoli</mat-label>
        <mat-select multiple placeholder="Ruoli" formControlName="ruoli">
            <mat-option *ngFor="let ruolo of ruoli" [value]="ruolo.id">{{ruolo.nome}}
            </mat-option>
        </mat-select>
    </mat-form-field>

</form>