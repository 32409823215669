import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ruolo } from '../models/ruolo';
import { ResourcefulService } from './resourcefulService';



@Injectable({
  providedIn: 'root'
})
export class RuoloService extends ResourcefulService<Ruolo>{
  constructor(private httpClient: HttpClient) {
    super(httpClient, Ruolo);
  }
}

