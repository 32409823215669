import { Component, OnInit } from '@angular/core';
import { SidebarRoute } from 'src/app/material-module/custom-sidebar/custom-sidebar.component';

@Component({
  selector: 'admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent implements OnInit {

  routes = [
    new SidebarRoute('Utenti', 'users',),
    new SidebarRoute('Comuni', 'comuni',),
    new SidebarRoute('Collegamenti', 'collegamenti',),
  ]


  constructor() {
  }

  ngOnInit() {
  }

}
