import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/material-module/confirm-dialog/confirm-dialog.component';
import { Comune } from 'src/app/models/comune';
import { ComuneService } from 'src/app/services/comune.service';
import { dataTableColumnDefinition } from 'src/app/shared/resourceful-index/dataTableColumnDefinition';
import { ResourcefulIndexComponent } from 'src/app/shared/resourceful-index/resourceful-index/resourceful-index.component';
import { EditComponent } from './dialogs/edit/edit.component';

@Component({
  selector: 'comuni',
  templateUrl: './comuni.component.html',
  styleUrls: ['./comuni.component.less']
})
export class ComuniComponent implements OnInit {
  headingText = 'Comuni';

  relations: string[] = ['provincia', 'provincia.regione'];

  @ViewChild('index') index: ResourcefulIndexComponent;

  selectionMode: boolean = false;

  loading: boolean = true;

  search: any = {};

  columnDefinitions: dataTableColumnDefinition[] = [
    new dataTableColumnDefinition(
      {
        humanName: 'ID',
        internalName: 'id',

      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Nome',
        internalName: 'nome',

      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'CAP',
        internalName: 'cap',

      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Provincia',
        internalName: 'provincia',
        sortDisabled: true,
        displayFunction: (model) => model.provincia?.nome,

      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Regione',
        internalName: 'regione',
        sortDisabled: true,
        displayFunction: (model) => model.provincia?.regione?.nome,

      }
    ),
  ];

  constructor(public comuneService: ComuneService,
    public dialog: MatDialog,
    private toast: ToastrService,
    @Optional() private dialogRef: MatDialogRef<ComuniComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,) {
    if (this.data?.selectionMode) {
      this.selectionMode = true;
      this.headingText = 'Seleziona un comune';
    }
  }

  form: FormGroup;

  async ngOnInit() {
    this.form = this.fb.group({
      nome: [],
      provincia: [],
    });

    this.form.valueChanges.subscribe(newValues => this.search = newValues);

    this.loading = false;
  }

  closeDialogWithSelectedModel(model) {
    this.dialogRef?.close(model);
  }

  async updateDataSource() {
    await this.index?.updateData();
  }


  deleteFunction = async (comune: Comune) => {
    try {
      let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: `Sei sicuro di voler eliminare il comune ${comune.nome}?` } });
      dialogRef.afterClosed().subscribe(async data => {
        if (data) {
          await this.comuneService.delete(comune);
          this.toast.success('Comune eliminato.');
          this.updateDataSource();
        }
      });
    } catch (e) {
      console.error(e);
      this.toast.error("Eliminazione comune fallita.", "Errore!");
    }
  }



  editFunction = async (comune: Comune) => {
    try {
      let dialogRef = this.dialog.open(EditComponent, { data: comune });
      dialogRef.afterClosed().subscribe(async (data: Comune) => {
        if (data) {
          Object.assign(comune, data);

          await this.comuneService.edit(comune);

          this.toast.success('Comune modificato');

        }
        this.updateDataSource();
      });

    } catch (e) {
      console.error(e);
      this.toast.error("Modifica comune fallita.", "Errore!");
    }
  }


  add() {
    let addDialogRef = this.dialog.open(EditComponent, { data: this.search });
    addDialogRef.afterClosed().subscribe(data => this.handleCreation(data));
  }

  async handleCreation(comune?: Comune) {
    if (comune) {
      try {
        await this.comuneService.create(comune)
        this.toast.success('Comune creato!')
      }
      catch (e) {
        this.toast.error('Creazione comune fallita.', 'Errore!');
      }
      finally {
        this.updateDataSource();
      }

    }
  }
}
