import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/material-module/confirm-dialog/confirm-dialog.component';
import { User } from 'src/app/models/user';
import { ListPropertyPipe } from 'src/app/pipe/list-property.pipe';
import { UserService } from 'src/app/services/user.service';
import { dataTableColumnDefinition } from 'src/app/shared/resourceful-index/dataTableColumnDefinition';
import { ResourcefulIndexComponent } from 'src/app/shared/resourceful-index/resourceful-index/resourceful-index.component';
import { ComuniComponent } from '../comuni/comuni.component';
import { EditComponent } from './dialogs/edit/edit.component';


@Component({
  selector: 'users-component',
  styleUrls: ['users.component.less'],
  templateUrl: 'users.component.html',
  providers: [ListPropertyPipe],

})

export class UsersComponent implements OnInit {

  headingText: string = 'Utenti';

  relations: string[] = ['ruoli', 'comune', 'comune.provincia'];

  @ViewChild('index') index: ResourcefulIndexComponent;

  selectionMode: boolean = false;

  loading: boolean = true;

  search: any = {};

  columnDefinitions: dataTableColumnDefinition[] = [
    new dataTableColumnDefinition(
      {
        humanName: 'ID',
        internalName: 'id',

      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Qualifica',
        internalName: 'qualifica',

      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Titolo',
        internalName: 'titolo',

      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Nome',
        internalName: 'humanIdentifier',
        sortDisabled: true,
      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'E-mail',
        internalName: 'email',

      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Ruoli',
        internalName: 'ruoli',
        sortDisabled: true,
        displayFunction: (model) => (this.listPropertyPipe.transform(model.ruoli)).substring(0, 25),

      }
    ), 

    new dataTableColumnDefinition(
      {
        humanName: 'Indirizzo',
        internalName: 'fullAddress',
        sortDisabled: true,

      }
    ),

    new dataTableColumnDefinition(
      {
        humanName: 'Telefono',
        internalName: 'tel',

      }
    ),
    new dataTableColumnDefinition(
      {
        humanName: 'Cellulare',
        internalName: 'cell',

      }
    ),

    new dataTableColumnDefinition(
      {
        humanName: 'Fax',
        internalName: 'fax',

      }
    ),

  ];

  constructor(public userService: UserService,
    public dialog: MatDialog,
    private toast: ToastrService,
    @Optional() private dialogRef: MatDialogRef<ComuniComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    public listPropertyPipe: ListPropertyPipe) {
    if (this.data?.selectionMode) {
      this.selectionMode = true;
      this.headingText = 'Seleziona un utente';
    }

    if (this.data?.defaultSearch) {
      this.search = this.data.defaultSearch;
    }
  }

  form: FormGroup;

  async ngOnInit() {

    this.loading = false;
  }

  closeDialogWithSelectedModel(model) {
    this.dialogRef?.close(model);
  }

  async updateDataSource() {
    await this.index?.updateData();
  }


  deleteFunction = async (user: User) => {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, { data: { message: `Sei sicuro di voler eliminare l'utente ${user.humanIdentifier}?` } });
    dialogRef.afterClosed().subscribe(async data => {
      if (!data) {
        this.toast.success('Account non eliminato.');
      }
      if (data) {

        try {
          await this.userService.delete(user);
          this.toast.success('Account eliminato.');
        }
        catch (e) {
          console.error(e);
          this.toast.error('Eliminazione Account fallita.', 'Errore!');
        }
        finally {
          this.updateDataSource();
        }

      }
    });
  }

  add() {
    let addDialogRef = this.dialog.open(EditComponent, { data: this.search });
    addDialogRef.afterClosed().subscribe(async data => {
      if (data) {
        let user = new User().deserialize(data);

        try {
          await this.userService.create(user);
          this.toast.success('Account creato.');
        } catch (e) {
          console.error(e);
          this.toast.error('Creazione account fallita.', 'Errore!');
        } finally {
          this.updateDataSource();
        }

      }
    });
  }

  editFunction = async (user: User) => {
    let editDialogRef = this.dialog.open(EditComponent, { data: user });

    editDialogRef.afterClosed().subscribe(async data => {


      if (data) {

        try {

          Object.assign(user, data);
          await this.userService.edit(user);
          this.toast.success('Account modificato.');
        } catch (e) {
          console.error(e);
          this.toast.error('Modifica account fallita.', 'Errore!');
        }
        finally {
          this.updateDataSource();
        }

      }
    });
  }
}
