import { AfterViewInit, Component, ElementRef, forwardRef, HostBinding, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { map, takeUntil } from 'rxjs/operators';
import { BaseFormControl } from '../base-form-control/base-form-control';



@Component({
  providers: [
    { provide: MatFormFieldControl, useExisting: forwardRef(() => NumberRangePickerComponent) },
  ],
  selector: 'number-range-picker',
  styleUrls: ['./number-range-picker.component.scss'],
  templateUrl: './number-range-picker.component.html',
})
export class NumberRangePickerComponent extends BaseFormControl implements AfterViewInit, OnDestroy {


  @Input()
  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.patchValue(value);
    this.stateChanges.next();
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  controlType: string = 'number-range-picker';

  @HostBinding()
  id = `${this.controlType}-${++NumberRangePickerComponent.nextId}`;

  get empty(): boolean {
    const n = this.form.value;

    return !n.start && !n.end;

  }

  onContainerClick(event: MouseEvent): void {
    {
      // if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      //   this.focusMonitor.focusVia(this.picker.nativeElement, 'mouse');
      // }
    }
  }

  get errorState(): boolean {
    this.changeDetection.detectChanges();
    if (!(this.form.dirty || this.form.touched)) return false;

    let start = this.form.value.start ?? -Infinity;
    let end = this.form.value.end ?? +Infinity;

    return end < start;

  }

  form = this.fb.group({
    start: [''],
    end: [''],
  });

  registerOnChange(onChange: (value: any | null) => void): void {
    this.form.valueChanges.pipe(
      takeUntil(this.destroy),
      map(val => {
        val.type = 'number-range';
        return val;
      })
    ).subscribe(onChange);
  }




  ngAfterViewInit(): void {
    this.focusMonitor.monitor(this.elementRef.nativeElement, true)
      .subscribe(focusOrigin => {
        this.focused = !!focusOrigin;

      });

  }


  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
  }


  setDisabledState(shouldDisable: boolean): void {
    if (shouldDisable) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this.disabled = shouldDisable;
  }

  writeValue(value: any | null): void {
    if (!value)
      return this.form.reset();
    this.form.patchValue(value, { emitEvent: false });

    this.stateChanges.next();
  }

}
