import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CustomMaterialModule } from '../material-module/material.module';
import { NavbarComponent } from './navbar.component';



@NgModule({
  declarations: [NavbarComponent],
  exports: [NavbarComponent],
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    CustomMaterialModule,
  ]
})
export class NavbarModule { }
