import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHTML'
})
export class StripHTMLPipe implements PipeTransform {

  transform(value: string): any {
    try {
      return value.replace(/<.*?>/g, ''); // remove html tags
    }
    catch (e) {
      console.warn("Failed stripping html. Returning empty string");
      return "";
    }
  }

}
