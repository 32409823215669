import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatClearableInputComponent } from './mat-clearable-input/mat-clearable-input.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CustomSidebarComponent } from './custom-sidebar/custom-sidebar.component';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
@NgModule({
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatSortModule, MatTooltipModule,
    MatButtonModule, MatCardModule, MatDialogModule, MatInputModule, MatTableModule,
    MatToolbarModule, MatMenuModule, MatIconModule, MatProgressSpinnerModule,
    MatSelectModule, MatOptionModule, MatCheckboxModule, MatProgressBarModule,
    MatDatepickerModule, MatSidenavModule, MatListModule, MatDividerModule, MatBadgeModule,
    MatTreeModule, MatRadioModule, MatSlideToggleModule, MatExpansionModule, TextFieldModule,

    NgxMaterialTimepickerModule.setLocale('it-IT'), RouterModule,
    DragDropModule,
  ],
  exports: [
    MatTooltipModule,
    CommonModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule, MatCardModule, MatDialogModule, MatInputModule, MatTableModule,
    MatToolbarModule, MatMenuModule, MatIconModule, MatProgressSpinnerModule,
    MatSelectModule, MatOptionModule, MatCheckboxModule, MatProgressBarModule,
    MatDatepickerModule, MatSidenavModule, MatListModule, MatDividerModule, MatBadgeModule,
    MatTreeModule, MatRadioModule, MatSlideToggleModule, TextFieldModule,
    NgxMaterialTimepickerModule, MatExpansionModule, MatClearableInputComponent,
    CustomSidebarComponent, DragDropModule,
  ],
  declarations: [ConfirmDialogComponent, MatClearableInputComponent, CustomSidebarComponent],
})
export class CustomMaterialModule { }
