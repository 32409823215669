import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomMaterialModule } from 'src/app/material-module/material.module';
import { CustomFormControlsModule } from 'src/app/shared/custom-form-controls/custom-form-controls.module';
import { EntityPickersModule } from 'src/app/shared/custom-form-controls/entity-pickers/entity-pickers.module';
import { ResourcefulIndexModule } from 'src/app/shared/resourceful-index/resourceful-index.module';
import { EditComponent } from './dialogs/edit/edit.component';
import { ProvinceComponent } from './province.component';
import { RegioniModule } from './regioni/regioni.module';



@NgModule({
  declarations: [ProvinceComponent, EditComponent],
  imports: [
    CommonModule,
    CustomMaterialModule,
    ReactiveFormsModule,
    ResourcefulIndexModule,
    RegioniModule,
    CustomFormControlsModule,
    EntityPickersModule,
  ],
  exports: [
    RegioniModule,
  ]
})
export class ProvinceModule { }
