import { Component, OnInit, ViewChild } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'signature-input',
  templateUrl: './signature-input.component.html',
  styleUrls: ['./signature-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SignatureInputComponent,
      multi: true
    }
  ]
})
export class SignatureInputComponent implements ControlValueAccessor {

  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  // https://github.com/szimek/signature_pad
  signaturePadOptions = { // passed through to szimek/signature_pad constructor
    'minWidth': 0.5,
    'maxWidth': 2.5,
    'canvasWidth': 1000,
    'canvasHeight': 300,
    'minDistance': 2,
  };
  onChange: Function;

  base64EncodedSignature: string;

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    //this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    this.base64EncodedSignature = this.signaturePad.toDataURL();
    if (this.onChange)
      this.onChange(this.base64EncodedSignature);
    console.log(this.base64EncodedSignature);
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  }


  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  writeValue(obj: any): void {
    this.base64EncodedSignature = obj;
    if (this.onChange)
      this.onChange(this.base64EncodedSignature);
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
  }

}

