<h4 mat-dialog-title>
  <span *ngIf="!data;else editHeader">
    Creazione nuovo collegamento
  </span>
  <ng-template #editHeader>
    Modifica collegamento {{data.nome}}
  </ng-template>
</h4>
<form *ngIf="form" [formGroup]="form" class="mt-4" (keydown.enter)="edit()">

  <mat-form-field>
    <mat-label>Nome</mat-label>
    <input matInput formControlName="nome">
    <mat-icon matSuffix>nome</mat-icon>
  </mat-form-field>

  <mat-form-field>

    <mat-label>
      URL
    </mat-label>
    <input matInput formControlName="url">
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      Ordinale
    </mat-label>
    <input matInput formControlName="ordinale">
  </mat-form-field>

</form>
<button mat-raised-button (click)="edit()" color="primary">Salva</button>