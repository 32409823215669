<div class="admin-panel-wrapper">
    <mat-sidenav-container>
        <mat-sidenav #sidenav mode="side" [opened]="opened" [position]="position">
            <button title="Chiudi barra di navigazione verticale" mat-icon-button (click)="sidenav.close()"
                class="sidenav-closer">
                <mat-icon>
                    close
                </mat-icon>
            </button>
            <h5 class="text-center mb-4 px-5">
                {{heading}}
            </h5>
            <ul class="macro-links">
                <li *ngFor="let route of routes" routerLinkActive="active-macro">
                    <ul>
                        <li>
                            <div *ngIf="contentMode=='router-outlet'; else openInNewTab" [routerLink]="route.path"
                                routerLinkActive="active-link">{{route.name}}</div>
                            <ng-template #openInNewTab>
                                <a [href]="route?.path" target="_blank">{{route.name}}</a>
                            </ng-template>
                        </li>
                    </ul>
                </li>

            </ul>
        </mat-sidenav>
        <mat-sidenav-content class="py-3">
            <router-outlet *ngIf="contentMode == 'router-outlet'">
            </router-outlet>
            <ng-content *ngIf="contentMode == 'ng-content'"></ng-content>
        </mat-sidenav-content>
    </mat-sidenav-container>
    <button title="Apri barra di navigazione verticale" class="sidenav-opener" *ngIf="!sidenav.opened" mat-icon-button
        (click)="sidenav.open()">
        <mat-icon>
            keyboard_arrow_right
        </mat-icon>
    </button>

</div>